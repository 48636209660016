import React from "react";

const VotesList = ({ votes }) => {
  const getVote = (v) => {
    if (v.vote) {
      return "F";
    } else if (v.vote !== null && !v.vote) {
      return "C";
    } else {
      return "A";
    }
  };

  return (
    <ul className="drawer__content__list">
      {votes.map((v) => {
        const decision = getVote(v);

        return (
          <li>
            {v.deputy.name}
            <i data-vote={decision}>{decision}</i>
          </li>
        );
      })}
    </ul>
  );
};

export default VotesList;
