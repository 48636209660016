import React, { useState } from 'react'
import PropTypes from 'prop-types';
import ServicePopUp from './ServicePopUp'

const ServiceCard = ({ service, popular }) => {
  const { image, title, description, price } = service;
  const [popUp, setPopUp] = useState(false);

  return (
    <div className={`service-card${image ? '' : ' --stretch'}`}>
      {image &&
        <div className="service-card__image">
          {popular && <span>Popular</span>}
          {image && <img src={image} alt="" />}
        </div>}

      {!image && popular && <span>Popular</span>}

      <div className="service-card__info">
        <h4>{title}</h4>
        <p>{description}</p>
        {price && <strong>${price}<span>MXN</span></strong>}
      </div>

      <button type="button" onClick={() => setPopUp(true)}>
        Ver
      </button>

      {popUp && <ServicePopUp service={service} popular={popular} discount={25} close={() => setPopUp(false)}/>}
    </div>
  )
};

ServiceCard.propTypes = {
  service: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.number,
  }),
  popular: PropTypes.bool,
}

export default ServiceCard;
