import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import SearchResults from './SearchResults';

const SearchBar = ({ icons, placeholder, path, className }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [focus, setFocus] = useState(false);

  useEffect(() => {
    const getSuggestions = async () => {
      if(searchQuery.trim() != '') {
        const suggestionsResult = await getSearchResults(searchQuery);
        setSuggestions(suggestionsResult);
      } else {
        setSuggestions([]);
      }
    }

    getSuggestions();
  }, [searchQuery])

  const getSearchResults = async (name) => {
    const response = await fetch(`${path}?search_name=${name}`);
    const results = await response.json();
    return results;
  }

  const handleBlur = () => { setTimeout(() => (setFocus(false)), 200)};

  return (
    <div className="searchbar">
      <label className={`${className}`}>
        <input
          placeholder={placeholder}
          value={searchQuery}
          type="search"
          onChange={({ target }) => setSearchQuery(target.value)}
          onFocus={() => setFocus(true)}
          onBlur={handleBlur}
        />
      </label>

      {focus && suggestions.length > 0 && (
        <SearchResults results={suggestions} icons={icons} />
      )}
    </div>
  )
};

SearchBar.propTypes = {
  icons: PropTypes.objectOf(PropTypes.string).isRequired,
  placeholder: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  className: PropTypes.string,
};

SearchBar.defaultProps = {
  className: '',
};

export default SearchBar;
