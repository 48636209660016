import React from 'react';
import { Slide } from 'react-slideshow-image';

const RecommendedEventsSlideshow = ({ events, arrowIcon }) => {
  const properties = {
    duration: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    indicators: true,
    prevArrow: <button type="button" className="arrow-prev"><img src={arrowIcon} alt="Anterior" /></button>,
    nextArrow: <button type="button" className="arrow-next"><img src={arrowIcon} alt="Siguiente" /></button>,
  };

  return (
    <Slide easing="ease" {...properties}>
      {events.map((event) => (
        <a href={ event.url } className="slide" key={ event.url }>
          <img src={ event.image } alt="Evento recomendado de puebla puebla" />
        </a>
      ))}
    </Slide>
  );
}

export default RecommendedEventsSlideshow;
