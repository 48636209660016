import React from 'react';
import ProcedureCards from './ProcedureCards';

const Procedure = ({procedures, i18n}) => {
  
  return (
    <section className="procedure" id="procedure">
      <div className="procedure__header">
        <h2 className="procedure__header__title">{i18n.header.title}</h2>
        <button className="procedure__header__button">{i18n.header.button}</button>
      </div>
      <div className="procedure__pagination">
        <div className="procedure__pagination__before"></div>
        <div className="procedure__pagination__next"></div>
      </div>
      <ProcedureCards procedures={procedures} i18nCard={i18n.card} />

    </section>
  )
}

export default Procedure;