import React, { useState } from 'react';
import PropTypes from 'prop-types';

const FilterCheckbox = ({ text, name, value, current, submit }) => {
  const [checked, setChecked] = useState(current ? current.includes(`${value}`) : false)
  
  return (
    <label>
      <input
        type="checkbox"
        name={name}
        value={value} 
        defaultChecked={checked}
        onChange={e => submit ? submit(e.target) : setChecked(e.target.checked)}
      />
      <span>{text}</span>
      <span className="checkbox" />
    </label>
  )
};

FilterCheckbox.propTypes = {
  text: PropTypes.string.isRequired,
  name:PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  current: PropTypes.arrayOf(PropTypes.string).isRequired,
  submit: PropTypes.func,
}

export default FilterCheckbox;
