import { Stream } from "@cloudflare/stream-react";
import React from "react";
import HlsPlayer from "./ott/streaming/HlsPlayer";
// import VideoJs from "./ott/streaming/VideoPlayer";

const videoUrl = (uid) =>
  uid
    ? `https://customer-agqk7n0hz61pv4iy.cloudflarestream.com/${uid}/manifest/video.m3u8`
    : "";

const VideoPlayer = ({ uid, live, videoProps = {} }) =>
  // live ? (
  //   // <VideoJs controls sources={[{ src: videoUrl(uid) }]} {...videoProps} />
  //   <HlsPlayer src={videoUrl(uid)} videoProps={videoProps} />
  // ) : (
    <Stream src={uid} controls {...videoProps} />
  // );

export default VideoPlayer;
