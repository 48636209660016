import React, { useState } from "react";
import Drawer from "./Drawer";

const btnIcon = (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 4.16705H1.33333V2.83372H0V4.16705ZM0 6.83372H1.33333V5.50039H0V6.83372ZM0 1.50039H1.33333V0.167053H0V1.50039ZM2.66667 4.16705H12V2.83372H2.66667V4.16705ZM2.66667 6.83372H12V5.50039H2.66667V6.83372ZM2.66667 0.167053V1.50039H12V0.167053H2.66667Z" />
  </svg>
);

const Attendance = ({ attendance }) => {
  const [defaultTable, setDefaultTable] = useState(true);

  return (
    <Drawer btnIcon={btnIcon} title="Asistencia">
      <table className="drawer__content__table">
        <thead>
          <tr>
            <th>Asistencia</th>
            <th>Inasistencia</th>
            <th>Con Licensia</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>10</td>
            <td>4</td>
            <td>7</td>
          </tr>
        </tbody>
      </table>
      <div className="drawer__content__tabs">
        <button
          className={`${defaultTable ? "--active" : ""}`}
          onClick={() => setDefaultTable(true)}
        >
          Por legislador
        </button>
        <button
          className={`${defaultTable ? "" : "--active"}`}
          onClick={() => setDefaultTable(false)}
        >
          Por partido
        </button>
      </div>

      <div className="drawer__content__listings">
        <h4>Detalle de asistencia</h4>
        <ul className="drawer__content__list --small">
          <li>
            Eduardo castillo lopez
            <i className="attendance-icon" title="Retraso justificado">
              RJ
            </i>
          </li>
          <li>
            Eduardo castillo lopez
            <i className="attendance-icon" title="Retraso justificado">
              RJ
            </i>
          </li>
          <li>
            Eduardo castillo lopez
            <i className="attendance-icon" title="Retraso justificado">
              RJ
            </i>
          </li>
        </ul>
        <h4>Detalle de inasistencia</h4>
        <ul className="drawer__content__list --small">
          <li>
            Eduardo castillo lopez
            <i className="attendance-icon" title="Retraso injustificado">
              RI
            </i>
          </li>
          <li>
            Eduardo castillo lopez
            <i className="attendance-icon" title="Retraso injustificado">
              RI
            </i>
          </li>
          <li>
            Eduardo castillo lopez
            <i className="attendance-icon" title="Retraso injustificado">
              RI
            </i>
          </li>
        </ul>
        <h4>Detalle de licencias</h4>
        <ul className="drawer__content__list --small">
          <li>
            Eduardo castillo lopez
            <i className="attendance-icon" title="Con licencia">
              CL
            </i>
          </li>
          <li>
            Eduardo castillo lopez
            <i className="attendance-icon" title="Con licencia">
              CL
            </i>
          </li>
          <li>
            Eduardo castillo lopez
            <i className="attendance-icon" title="Con licencia">
              CL
            </i>
          </li>
        </ul>
      </div>
    </Drawer>
  );
};

export default Attendance;
