import React, { useState } from 'react';

const HomeHeader = ({ icons }) => {
    const [activeDropdown, setActiveDropdown] = useState(false);

    const clickOnHero = () => {
        const hero = document.getElementById('hero-search');
        hero.click();

        setTimeout(() => hero.focus(), 50);
    }

    return (
        <div className="header-main__mobile">
            <div
                className="header-main__mobile__dropdown"
                dangerouslySetInnerHTML={{ __html: icons.dropdown }}
                onClick={() => { setActiveDropdown(true) }}
            >
            </div>
            <div
                className="header-main__mobile__logo"
                onClick={() => {document.location = '/'}}
                dangerouslySetInnerHTML={{ __html: icons.logo }}
            >
            </div>
            <div className="header-main__mobile__user"
                dangerouslySetInnerHTML={{ __html: icons.search }}
                onClick={() => clickOnHero()}
            >
            </div>
            {
                activeDropdown &&
                    <div
                        className="header-main__mobile__menu-area"
                        
                    >
                        <div className="header-main__mobile__menu-area__body">
                            <div className="header-main__mobile__menu-area__header">
                                <div
                                    className="header-main__mobile__menu-area__header__close"
                                    dangerouslySetInnerHTML={{ __html: icons.close }}
                                    onClick={() => {setActiveDropdown(false)}}
                                ></div>
                                <span className="header-main__mobile__menu-area__header__title">Menú</span>
                            </div>
                            <div className="header-main__mobile__menu-area__content">
                                
                                <div className="header-main__mobile__menu-area__body__item">
                                    <a href="/pricing">
                                        Suscribe tu negocio
                                    </a>
                                    <div className="header-main__mobile__menu-area__body__item__icon" dangerouslySetInnerHTML={{ __html: icons.arrow_right }}></div>
                                </div>
                            </div>
                            <div className="header-main__mobile__menu-area__footer">
                                <div className="header-main__mobile__menu-area__footer__items">
                                    <a className="header-main__mobile__menu-area__footer__items__item">
                                        <a href="/acerca_de">
                                            Acerca de
                                        </a>
                                    </a>
                                    <a className="header-main__mobile__menu-area__footer__items__item">
                                        <a href="/politica_de_privacidad">
                                            Política de privacidad
                                        </a>
                                    </a>
                                    <a className="header-main__mobile__menu-area__footer__items__item">
                                        <a href="/terminos_de_uso">
                                            Términos de uso
                                        </a>
                                    </a>
                                    <a className="header-main__mobile__menu-area__footer__items__item">
                                        <a href="/contacto">
                                            Contacto
                                        </a>
                                    </a>
                                </div>
                                <div className="header-main__mobile__menu-area__footer__bottom">
                                    <div dangerouslySetInnerHTML={{ __html: icons.yovi }}></div>
                                    <div className="header-main__mobile__menu-area__footer__bottom__social">
                                        <a href="https://www.facebook.com/grupoYOVI/">Facebook</a>
                                        <a href="https://www.instagram.com/grupoyovi/">Instagram</a>
                                    </div>
                                    <a className="header-main__mobile__menu-area__footer__bottom__rights">© 2021 YOVI - Todos los derechos reservados</a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="header-main__mobile__menu-area__close-area"
                            onClick={() => {setActiveDropdown(false)}}
                        ></div>
                    </div>
            }
            <div className="nav__botton">
                <div className="nav__botton__link">
                    <a href="/" >Qué hacer</a>
                    <div dangerouslySetInnerHTML={{ __html: icons.quehacer }}></div>
                </div>
                <div className="nav__botton__link">
                    <a href="/directorio">Directorio</a>
                    <div dangerouslySetInnerHTML={{ __html: icons.directory }}></div>
                </div>
                <div className="nav__botton__link">
                    <a href="/eventos">Eventos</a>
                    <div dangerouslySetInnerHTML={{ __html: icons.events }}></div>
                </div>
            </div>
        </div>
    )
}

export default HomeHeader
