import React, { useState } from 'react';

const PlanTooltip = ({ image }) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <button onClick={() => setVisible(!visible)} onBlur={() => setVisible(false)} />
      {visible && (
        <div className="info-tooltip" dangerouslySetInnerHTML={{__html: image}} />
      )}
    </>
  )
};

export default PlanTooltip;
