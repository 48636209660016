import React, { useState } from "react";

const arrowRight = (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.324707 8.81667L4.14137 5L0.324707 1.175L1.49971 0L6.49971 5L1.49971 10L0.324707 8.81667Z"
      fill="#262626"
    />
  </svg>
);

const playIcon = (
  <svg
    width="74"
    height="74"
    viewBox="0 0 74 74"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.6654 53.4987V20.4987L51.6654 36.9987M36.9987 0.332031C32.1836 0.332031 27.4156 1.28044 22.967 3.12312C18.5184 4.96579 14.4763 7.66663 11.0714 11.0714C4.19512 17.9478 0.332031 27.2741 0.332031 36.9987C0.332031 46.7233 4.19512 56.0496 11.0714 62.9259C14.4763 66.3308 18.5184 69.0316 22.967 70.8743C27.4156 72.717 32.1836 73.6654 36.9987 73.6654C46.7233 73.6654 56.0496 69.8023 62.9259 62.9259C69.8023 56.0496 73.6654 46.7233 73.6654 36.9987C73.6654 32.1836 72.717 27.4156 70.8743 22.967C69.0316 18.5184 66.3308 14.4763 62.9259 11.0714C59.5211 7.66663 55.479 4.96579 51.0304 3.12312C46.5818 1.28044 41.8138 0.332031 36.9987 0.332031Z"
      fill="#FCFCFC"
    />
  </svg>
);

const GrandstandVideo = ({ articles }) => {
  const [firstPage, setFirstPage] = useState(0);
  const handleArrowClick = () => setFirstPage(firstPage === 0 ? 4 : 0);

  const checkHidden = (index) => {
    return (index > firstPage && index < firstPage + 4)
  };

  const mainArticle = articles[firstPage];

  return (
    <section className="grandstand-video">
      <div className="grandstand-voice__header">
        <h2 className="grandstand-voice__header__title">Videos de GRADA</h2>

        {articles.length > 4 && (
          <div className="grandstand-voice__header__controls">
            <button
              className="--invert-x swiper-control"
              onClick={handleArrowClick}
            >
              {arrowRight}
            </button>
            <button
              className="swiper-control"
              onClick={handleArrowClick}
            >
              {arrowRight}
            </button>
          </div>
        )}

      </div>
      <a href={mainArticle.url} className="main-card">
        <div className="main-card__image-area">
          <img src={mainArticle.image} />
          <div className="main-card__image-area__icon">{playIcon}</div>
          <div className="main-card__content">
            <p className="main-card__content__tag">{mainArticle.category}</p>
            <p className="main-card__content__title">{mainArticle.title}</p>
          </div>
        </div>
      </a>
      <div className="grandstand-video__cards">
        {articles.map(({ title, image, url }, index) => (
          <a
            href={url}
            key={title}
            className={`card ${checkHidden(index) ? '' : '--hidden'} ${firstPage === index ? '--hide-main' : ''}`}>
            <div className="card__image">
              <img src={image} />
              <div className="card__image__icon">{playIcon}</div>
            </div>
            <h3 className="card__title">{title}</h3>
          </a>
        ))}
      </div>
    </section>
  );
};

export default GrandstandVideo;
