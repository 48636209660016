import React from 'react'
import PropTypes from 'prop-types'

const MobileFilterSwitch = props => {
    return (
        <>
            <label className="form-label" htmlFor="online-tickets">Boletos Online</label>
            <label className="switch">
                <input
                    type="checkbox"
                    id="online-tickets"
                    name="online_sales"
                    
                />
                <span className="slider round"></span>
            </label>
        </>
    )
}

MobileFilterSwitch.propTypes = {

}

export default MobileFilterSwitch
