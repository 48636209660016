import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Slide } from 'react-slideshow-image';
import EventOptions from "../HomePage/components/EventOptions";

const CommerceSlides = ({ slides, icons, optClass, desktopControls }) => {
  const [lightBoxDisplay, setLightBoxDisplay] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);
  const container = useRef();

  const { arrowIcon, galleryIcon, closeIcon } = icons;

  const properties = {
    defaultIndex: initialSlide,
    slidesToShow: 1,
    slidesToScroll: 1,
    indicators: true,
    autoplay: false,
    prevArrow: <button type="button" className="arrow-prev"><img src={arrowIcon} alt="Anterior" /></button>,
    nextArrow: <button type="button" className="arrow-next"><img src={arrowIcon} alt="Siguiente" /></button>,
  };

  const handleLightBox = e => {
    if (e.target.classList.contains('commerce-header__images__overlay')) {
      setLightBoxDisplay(false);
    };
  };

  return (
    <div className="article-details__carrusel-wrapper">
      <div className="article-details__carrusel-wrapper__header">
        <h3>
          <img src={galleryIcon} alt="Galería" />
          Ver más fotos
        </h3>

        {desktopControls && (
          <EventOptions cards={container} />
        )}
      </div>
    
      <div className={optClass ?? ''} ref={container}>      
        {slides.map((image, index) => (
          <div className="image-wrapper">
            <img
              src={image}
              className="commerce-header__images__image"
              alt=""
              key={image}
              onClick={() => {setLightBoxDisplay(true); setInitialSlide(index);}}
            />
          </div>
        ))}
      </div>

      {lightBoxDisplay && (
        <div
          className="commerce-header__images__overlay"
          onClick={(e) => handleLightBox(e)}
        >
          <div className="go-back-button">
            <button onClick={() => setLightBoxDisplay(false)}>
              <span>
                VOLVER
              </span>
              <img src={closeIcon} />
            </button>
          </div>
          <Slide easing="ease" {...properties}>
            {slides.map(slide => (
              <img
                src={slide}
                alt=""
                key={`lightbox-${slide}`}
              />
            ))}
          </Slide>
        </div>
      )}
    </div>
  )
};

CommerceSlides.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.string).isRequired,
  arrowIcon: PropTypes.string.isRequired,
};

export default CommerceSlides;
