import React, { useRef } from 'react';
import PropTypes from 'prop-types';

const arrowRight = (
  <svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.66094 10L0.648438 2.98754L2.6516 0.984375L11.6673 10L2.6516 19.0157L0.648438 17.0125L7.66094 10Z" fill="#FD495C" />
  </svg>
);

const SubcategoryList = ({ current, subcategories }) => {
  const list = useRef();

  const handleScroll = () => {
    const { scrollLeft, scrollWidth, offsetWidth } = list.current;

    list.current.scrollTo({
      top: 0,
      left: offsetWidth === scrollWidth - scrollLeft ? 0 : scrollLeft + 300,
      behavior: 'smooth',
    });
  };

  return (
    <>
      <ul className="subcategory-list" ref={list}>
        <li className="subcategory-list__current">
          {current.iconUrl && <img src={current.iconUrl} alt='' />}
          <span>
            {current.name}
          </span>
        </li>

        {subcategories.map((sbctgy) => (
          <li key={sbctgy.name}>
            {sbctgy.iconUrl && <img src={sbctgy.iconUrl} alt='' />}
            <a href={sbctgy.url}>
              {sbctgy.name}
            </a>
          </li>
        ))}
      </ul>

      <button type="button" onClick={handleScroll}>{arrowRight}</button>
    </>
  );
};

SubcategoryList.propTypes = {
  current: PropTypes.shape({
    name: PropTypes.string.isRequired,
    iconUrl: PropTypes.string.isRequired,
  }).isRequired,
  subcategories: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default SubcategoryList;
