import React, { useEffect, useRef, useState } from "react";
import { Hls } from "hls.js";

const HlsPlayer = ({ src, videoProps }) => {
  const video = useRef();
  const [controls, setControls] = useState(false);

  useEffect(() => {
    if (Hls.isSupported()) {
      const hls = new Hls();

      console.log(hls);

      hls.loadSource(src);
      hls.attachMedia(video.current);
    }
  }, []);

  const playVideo = () => {
    video.current.play();
  };

  return (
    <div className="video-hls">
      <video ref={video} controls={controls} onPlay={() => setControls(true)} />
      {!controls && (
        <>
          <img src={videoProps.poster} />
          <button className="hls-play-btn" onClick={playVideo} />
        </>
      )}
    </div>
  );
};

export default HlsPlayer;
