import React, { useState } from 'react';
import PropTypes from 'prop-types';

const EventCategoryMobile = ({
    categories,
    categoryActive,
    categoryText,
}) => {
    const arrowIcon = (<svg width="14" height="8" viewBox="0 0 14 8" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99999 5.17205L11.95 0.222046L13.364 1.63605L6.99999 8.00005L0.635986 1.63605L2.04999 0.222046L6.99999 5.17205Z" />
    </svg>);
    
    const [showCategory, setShowCategory] = useState(false);

    return (
        <div className="dropdown-category">
            <div
                className="dropdown-category__button"
                onClick={ () => setShowCategory(!showCategory) }
            >
                <p className={!categoryActive ? '--muted' : ''}>{ categoryActive ? categoryActive : categoryText }</p>
                { arrowIcon }
            </div>
            {
                showCategory && 
                    <ul className="dropdown-category__categories">
                        {
                            categories.map(category => (
                                <a
                                    className={`dropdown-category__categories__category ${ category.name == categoryActive ? '--active' : ''}`}
                                    href={ category.url }
                                    key={ category.id}
                                >{ category.name }</a>
                            ))
                        }
                    </ul>
            }
            
        </div>
    );
}

EventCategoryMobile.propTypes = {

}

EventCategoryMobile.default = {
    categoryText: 'Seleccionar categoria'
}

export default EventCategoryMobile;
