import React from 'react';
import { useEventFilters } from '../../hooks/useEventFilters';

const EventCategoryOrder = () => {
    const {
        urlSearch,
        selectOption,
        checkOnlineSales,
        eventOrders,
    } = useEventFilters();

    const handleSelectOption = ({ target }) => {
        selectOption(target);
    }

    const handleCheckOnlineSales = ({ target }) => {
        checkOnlineSales(target);
    }

    return (
        <div class="events-order">
            <div class="events-order__by-data">
                <label>Ordenar por</label>
                <select
                    name="order"
                    onChange={ handleSelectOption }
                    value={ urlSearch.order }
                >
                    { eventOrders.map(eventOrder => (
                        <option
                            key={ eventOrder.value }
                            value={ eventOrder.value }
                        >{ eventOrder.name }</option>
                    ))}
                </select>
            </div>
            <div class="events-order__by-page">
                <label>Mostrar por pagina</label>
                <select>
                    <option>10</option>
                </select>
            </div>
            <div class="events-order__by-online-sales">
                <label>Boletos Online</label>
                <label class="switch">
                    <input
                        type="checkbox"
                        id="online-tickets"
                        name="online_sales"
                        checked={ urlSearch.online_sales }
                        onChange={ handleCheckOnlineSales }
                    />
                    <span class="slider round"></span>
                </label>
            </div>
        </div>
    );
}

export default EventCategoryOrder;
