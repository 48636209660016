import React from 'react';
import PropTypes from 'prop-types';
import { Slide } from 'react-slideshow-image';
import PictureTag from '../../Aplication/components/PictureTag';

const HighTierSlideShow = ({ slides, mobileSlides, arrowIcon, path }) => {
  const properties = {
    duration: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    indicators: true,
    prevArrow: <button type="button" className="arrow-prev"><img src={arrowIcon} alt="Anterior" /></button>,
    nextArrow: <button type="button" className="arrow-next"><img src={arrowIcon} alt="Siguiente" /></button>,
  };

  return (
    <Slide easing="ease" {...properties}>
      {slides.map((img, index) => (
        <a href={path}>
          <div className="slide" key={img}>
            <PictureTag desktopImg={img} mobileImg={mobileSlides[index]} defaultImg={img} />
            {/* <img src={img} alt="slide" /> */}
          </div>
        </a>
      ))}
    </Slide>
  );
};

HighTierSlideShow.propTypes = {
  slides: PropTypes.arrayOf(PropTypes.string).isRequired,
  arrowIcon: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};

export default HighTierSlideShow;
