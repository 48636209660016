import React, { useState, useRef } from 'react'
import EventCards from './EventCards';
import EventOption from './EventOptions'

const Event = ({ header, events }) => {
  
  const [category, setCategory] = useState(events[0]);
  const cards = useRef();

  /* Events filter */
  const categories = events.filter(event => {
    if (event.content.length > 0) {
      return event.name
    }
  })
  
  const categoriesNames = categories.map(item => item.name);

  const { name: categoryName } = category;

  const changeEventSelected = (selectedName) => {
    setCategory(categories.find(item => item.name === selectedName));
  }

  return (
    <section className="event" id="events">
      <div className="event__header">
        <h2 className="event__header__title">{header.title}</h2>
        <a href={header.indexPath} className="event__header__button">{header.button}</a>
      </div>

      <EventOption
        category={categoryName}
        setCategory={setCategory}
        categories={ categoriesNames }
        changeEventSelected={ changeEventSelected }
        cards={cards}
      />

      <EventCards
        eventSelected={category}
        cards={cards}
      />

    </section>
  )
}

export default Event;