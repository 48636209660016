import React from 'react'
import PropTypes from 'prop-types'

const MobileFilterSelect = ({ name, options }) => {
    return (
        <select className="form-select-mobile-filters">
            <option>{ name }</option>
                {
                    options.map(option => (
                        <option
                            value={option.value}
                            key={option.name}
                        >{option.name}</option>
                    ))
                }
        </select>
    )
}

MobileFilterSelect.propTypes = {

}

MobileFilterSelect.defaultProps = {
    options: []
}

export default MobileFilterSelect;
