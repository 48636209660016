import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/swiper-bundle.css";

const SwipeControl = ({ back, refer }) => {
  const swiper = useSwiper();
  return (
    <button
      ref={refer}
      onClick={() => (back ? swiper.slidePrev() : swiper.slideNext())}
      className="d-none"
    />
  );
};

const Comissions = ({ items }) => {
  const [swiped, setSwiped] = useState(false);
  const slideBack = useRef();
  const slideForward = useRef();

  const handleSwipeAhead = () => {
    slideForward.current.click();
    setSwiped(true);
  };

  return (
    <>
      <div className="comission-cards">
        {swiped && (
          <div
            className="playlist-container__arrow --arrow-back"
            onClick={() => slideBack.current.click()}
          >
            <svg
              width="13"
              height="21"
              viewBox="0 0 13 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.316406 18.1333L7.94974 10.5L0.316406 2.85L2.66641 0.5L12.6664 10.5L2.66641 20.5L0.316406 18.1333Z"
                fill="#C6C6C6"
              />
            </svg>
          </div>
        )}

        <Swiper
          slidesPerView={"auto"}
          className="comission-swiper"
          loop={false}
        >
          <SwipeControl refer={slideBack} back />
          <SwipeControl refer={slideForward} />
          {items.map(({ id, name, url, icon }) => (
            <SwiperSlide key={id}>
              <a href={url} className="card">
                <div className="comission-logo">
                  <img src={icon} alt="" />
                </div>
                <p>{name}</p>
              </a>
            </SwiperSlide>
          ))}
        </Swiper>
        {items.length > 4 && (
          <div className="playlist-container__arrow" onClick={handleSwipeAhead}>
            <svg
              width="13"
              height="21"
              viewBox="0 0 13 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.316406 18.1333L7.94974 10.5L0.316406 2.85L2.66641 0.5L12.6664 10.5L2.66641 20.5L0.316406 18.1333Z"
                fill="#C6C6C6"
              />
            </svg>
          </div>
        )}
      </div>
    </>
  );
};

export default Comissions;
