import React from 'react';
import ProcedureCard from './ProcedureCard';

const ProcedureCards = ({procedures, i18nCard}) => {

  return (
    <div className="procedure__cards">
      {
        procedures.map(procedure => <ProcedureCard {...procedure} key={procedure.id} i18nCard={i18nCard} />)
      }
    </div>
  )
}

export default ProcedureCards;
