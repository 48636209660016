import React from 'react'
import PropTypes from 'prop-types'

// Add url params below
const CategoryCard = ({ name, url, iconUrl }) => {
    
    return (
        <a href={ url } className="category-body__cards__card">
            <p className="category-body__cards__card__title">{ name }</p>
            <div className="category-body__cards__card__icon">
                <svg>
                    <image href={ iconUrl } src={ iconUrl } width="100%" height="100%"/>
                </svg>
            </div>
        </a>
    );
}

CategoryCard.propTypes = {

}

export default CategoryCard;
