import React from 'react'
import PropTypes from 'prop-types'
import StreamingHeader from './StreamingHeader'
import VideoHeader from './VideoHeader'

const OttHeader = ({ live }) => (
    live ? 
    <StreamingHeader />
    :
    <VideoHeader />
);

OttHeader.propTypes = {
  live: PropTypes.bool.isRequired,
};

export default OttHeader
