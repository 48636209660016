import React from 'react';
import PropTypes from 'prop-types';

const MobileDropdownList = ({ title, listItems, current }) => (
  <>
    <p id={title} className="dual-dropdown__list-title">{title}</p>

    <ul aria-labelledby={title} className="dual-dropdown__list">
      <li key={current.name} className="dual-dropdown__list__current">
        <a href={current.url}>
          {current.name}
        </a>
      </li>

      {listItems.map((item) => (
        <li key={item.name}>
          <a href={item.url}>
            {item.name}
          </a>
        </li>
      ))}
    </ul>
  </>
);

MobileDropdownList.propTypes = {
  title: PropTypes.string.isRequired,
  listItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  current: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default MobileDropdownList;
