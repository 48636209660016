import React, { useState } from "react";
import Drawer from "./Drawer";
import VotesList from "./VotesList";
import VotesTable from "./VotesTable";

const btnIcon = (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 4.16705H1.33333V2.83372H0V4.16705ZM0 6.83372H1.33333V5.50039H0V6.83372ZM0 1.50039H1.33333V0.167053H0V1.50039ZM2.66667 4.16705H12V2.83372H2.66667V4.16705ZM2.66667 6.83372H12V5.50039H2.66667V6.83372ZM2.66667 0.167053V1.50039H12V0.167053H2.66667Z" />
  </svg>
);

const votes = [
  {
    vote: null,
    deputy: { id: 1, name: "Eduardo Castillo López" },
    party: { id: 1, name: "Grada" },
  },
  {
    vote: true,
    deputy: { id: 2, name: "Eduardo Castillo López" },
    party: { id: 2, name: "Grada" },
  },
  {
    vote: true,
    deputy: { id: 3, name: "Eduardo Castillo López" },
    party: { id: 3, name: "Grada" },
  },
  {
    vote: false,
    deputy: { id: 4, name: "Eduardo Castillo López" },
    party: { id: 4, name: "Grada" },
  },
  {
    vote: true,
    deputy: { id: 5, name: "Eduardo Castillo López" },
    party: { id: 2, name: "Grada" },
  },
  {
    vote: false,
    deputy: { id: 6, name: "Eduardo Castillo López" },
    party: { id: 3, name: "Grada" },
  },
  {
    vote: true,
    deputy: { id: 7, name: "Eduardo Castillo López" },
    party: { id: 2, name: "Grada" },
  },
  {
    vote: false,
    deputy: { id: 8, name: "Eduardo Castillo López" },
    party: { id: 3, name: "Grada" },
  },
  {
    vote: true,
    deputy: { id: 9, name: "Eduardo Castillo López" },
    party: { id: 4, name: "Grada" },
  },
  {
    vote: null,
    deputy: { id: 10, name: "Eduardo Castillo López" },
    party: { id: 4, name: "Grada" },
  },
  {
    vote: true,
    deputy: { id: 11, name: "Eduardo Castillo López" },
    party: { id: 1, name: "Grada" },
  },
  {
    vote: true,
    deputy: { id: 12, name: "Eduardo Castillo López" },
    party: { id: 2, name: "Grada" },
  },
  {
    vote: true,
    deputy: { id: 13, name: "Eduardo Castillo López" },
    party: { id: 1, name: "Grada" },
  },
  {
    vote: true,
    deputy: { id: 14, name: "Eduardo Castillo López" },
    party: { id: 4, name: "Grada" },
  },
  {
    vote: null,
    deputy: { id: 16, name: "Eduardo Castillo López" },
    party: { id: 4, name: "Grada" },
  },
  {
    vote: false,
    deputy: { id: 17, name: "Eduardo Castillo López" },
    party: { id: 2, name: "Grada" },
  },
  {
    vote: null,
    deputy: { id: 18, name: "Eduardo Castillo López" },
    party: { id: 3, name: "Grada" },
  },
];

const Votes = () => {
  const [defaultTable, setDefaultTable] = useState(true);
  
  return (
    <Drawer btnIcon={btnIcon} title="Votaciones">
      <table className="drawer__content__table">
        <thead>
          <tr>
            <th>Favor</th>
            <th>En Contra</th>
            <th>Abstención</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{votes.filter((v) => v.vote).length}</td>
            <td>{votes.filter((v) => v.vote !== null && !v.vote).length}</td>
            <td>{votes.filter((v) => v.vote === null).length}</td>
            <td>{votes.length}</td>
          </tr>
        </tbody>
      </table>
      <div className="drawer__content__tabs">
        <button className={`${defaultTable ? '--active' : ''}`} onClick={() => setDefaultTable(true)}>Por partido</button>
        <button className={`${defaultTable ? '' : '--active'}`} onClick={() => setDefaultTable(false)}>Por legislador</button>
      </div>
      <h4>Detalle de votaciones</h4>
      {defaultTable ?
        <VotesTable votes={votes} />
      :
        <VotesList votes={votes} />
      }
    </Drawer>
  );
};

export default Votes;
