import React from 'react';
import EventCard from './EventCard';

const EventCards = ({eventSelected, past, cards}) => {
  return (
    <div className={past ? 'event__cards--past' : 'event__cards'} ref={cards}>
      {
        eventSelected.content.map(event => <EventCard {...event} key={event.id} />)
      }
    </div>
  )
}

export default EventCards;
