import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ScheduleTooltip = ({ schedule, icon }) => {
  const [showSchedule, setShowSchedule] = useState(false);
  const translation = {
    sunday: 'Domingo',
    monday: 'Lunes',
    tuesday: 'Martes',
    wednesday: 'Miércoles',
    thursday: 'Jueves',
    friday: 'Viernes',
    saturday: 'Sábado'
  }

  const formatDaySchedule = (day) => {
    if (schedule[day].length > 0) {
      return `${schedule[day][0].opening} - ${schedule[day][0].closing}`
    };

    return 'Cerrado'
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowSchedule(!showSchedule)}
        onBlur={() => setShowSchedule(false)}
      >
        <span dangerouslySetInnerHTML={{__html: icon}} />
      </button>

      {showSchedule && (
        <table>
          <tbody>
            {Object.keys(schedule).slice(1, 7).map(day => (
              <tr key={day}>
                <td><strong>{translation[day]}</strong></td>
                <td>{formatDaySchedule(day)}</td>
              </tr>
            ))}
            <tr>
              <td><strong>{translation['sunday']}</strong></td>
              <td>{formatDaySchedule('sunday')}</td>
            </tr>
          </tbody>
        </table>
      )}
    </>
  )
};

ScheduleTooltip.propTypes = {
  schedule: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
}

export default ScheduleTooltip;
