import React from 'react';

const EventCard = ({ title, hour, place, date, coverPageUrl, path }) => {
  const clockIcon = (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M6.00033 11.8337C2.77858 11.8337 0.166992 9.22208 0.166992 6.00033C0.166992 2.77858 2.77858 0.166992 6.00033 0.166992C9.22208 0.166992 11.8337 2.77858 11.8337 6.00033C11.8337 9.22208 9.22208 11.8337 6.00033 11.8337ZM6.00033 10.667C7.238 10.667 8.42499 10.1753 9.30016 9.30016C10.1753 8.42499 10.667 7.238 10.667 6.00033C10.667 4.76265 10.1753 3.57566 9.30016 2.70049C8.42499 1.82532 7.238 1.33366 6.00033 1.33366C4.76265 1.33366 3.57566 1.82532 2.70049 2.70049C1.82532 3.57566 1.33366 4.76265 1.33366 6.00033C1.33366 7.238 1.82532 8.42499 2.70049 9.30016C3.57566 10.1753 4.76265 10.667 6.00033 10.667ZM6.58366 6.00033H8.91699V7.16699H5.41699V3.08366H6.58366V6.00033Z" fill="#FD495C"/>
  </svg>
  );

  const placeIcon = (
    <svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 18.8999L13.95 13.9499C14.9289 12.9709 15.5955 11.7236 15.8656 10.3658C16.1356 9.00795 15.9969 7.60052 15.4671 6.32148C14.9373 5.04244 14.04 3.94923 12.8889 3.18009C11.7378 2.41095 10.3844 2.00043 9 2.00043C7.61557 2.00043 6.26222 2.41095 5.11109 3.18009C3.95996 3.94923 3.06275 5.04244 2.53292 6.32148C2.00308 7.60052 1.86442 9.00795 2.13445 10.3658C2.40449 11.7236 3.07111 12.9709 4.05 13.9499L9 18.8999ZM9 21.7279L2.636 15.3639C1.37734 14.1052 0.520187 12.5016 0.172928 10.7558C-0.17433 9.00995 0.00390685 7.20035 0.685099 5.55582C1.36629 3.91129 2.51984 2.50569 3.99988 1.51677C5.47992 0.527838 7.21998 0 9 0C10.78 0 12.5201 0.527838 14.0001 1.51677C15.4802 2.50569 16.6337 3.91129 17.3149 5.55582C17.9961 7.20035 18.1743 9.00995 17.8271 10.7558C17.4798 12.5016 16.6227 14.1052 15.364 15.3639L9 21.7279ZM9 10.9999C9.53044 10.9999 10.0391 10.7892 10.4142 10.4141C10.7893 10.0391 11 9.53035 11 8.99992C11 8.46949 10.7893 7.96078 10.4142 7.58571C10.0391 7.21064 9.53044 6.99992 9 6.99992C8.46957 6.99992 7.96086 7.21064 7.58579 7.58571C7.21072 7.96078 7 8.46949 7 8.99992C7 9.53035 7.21072 10.0391 7.58579 10.4141C7.96086 10.7892 8.46957 10.9999 9 10.9999ZM9 12.9999C7.93914 12.9999 6.92172 12.5785 6.17158 11.8283C5.42143 11.0782 5 10.0608 5 8.99992C5 7.93906 5.42143 6.92164 6.17158 6.17149C6.92172 5.42135 7.93914 4.99992 9 4.99992C10.0609 4.99992 11.0783 5.42135 11.8284 6.17149C12.5786 6.92164 13 7.93906 13 8.99992C13 10.0608 12.5786 11.0782 11.8284 11.8283C11.0783 12.5785 10.0609 12.9999 9 12.9999Z" fill="#FD495C"/>
    </svg>
  );

  return (
    <a href={path} className="event-card">
      <div className="event-card__image" style={{backgroundImage: `url(${coverPageUrl})`}}>
        {
          //<div className="event-card__date">{date ?? '20 abr'}</div>
        }
      </div>
      <div className="event-card__information">
        <h3 className="event-card__information__title">{title}</h3>
        {hour && <div className="event-card__information__time">
          { clockIcon }
          <span>{hour}</span>
        </div>}
        <div className="event-card__information__date">{date ?? '20 abr'}</div>
        {/*place && <div className="event-card__information__place">
          { placeIcon }
          <span>{place.length > 20 ? place.slice(0, 20)+'...' : place}</span>
        </div>*/}
      </div>
    </a>
  )
}

export default EventCard;