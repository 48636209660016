import React from 'react';
import PropTypes from 'prop-types'

const ServicePopUp = ({ service, share, discount, popular, close }) => {
  const { image, title, description, price } = service;

  return (
    <div className="service-popup">
      <div className="service-popup__card">
        <button onClick={close} />
        <img src={image} alt="" />
        {popular && <span>Popular</span>}
        <div className="service-popup__card__info">
          <h2>{title}</h2>
          <p>{description}</p>
          {price && <strong>${price}<span>MXN</span></strong>}
          {!price && <a href="#" className="service-popup__card__info__button">Cotizar</a>}
          {discount && <span className="service-popup__card__info__button">{discount}% Descuento</span>}
          <a href={share} className="service-popup__card__info__share">Compartir</a>
        </div>
      </div>
    </div>
  )
};

ServicePopUp.propTypes = {
  service: PropTypes.shape({
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }),
  share: PropTypes.string.isRequired,
  discount: PropTypes.number,
  popular: PropTypes.bool,
  close: PropTypes.func.isRequired,
}

export default ServicePopUp;
