import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import buildUrl from '../../helpers/ctaUrl';
import CtaFormModal from './CtaFormModal';

const CallToAction = ({
  cta, whatsapp, phone, coordinates, commerce, token, path, icons, message,
}) => {
  const [ctaLink, setCtaLink] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (['Informes', 'Cotizar'].includes(cta)) {
      setCtaLink(false);
    } else {
      const coords = JSON.parse(coordinates);

      setCtaLink(buildUrl(cta, {
        phone,
        message: message ? encodeURI(message) : '%C2%A1Hola%21+Te+encontr%C3%A9+en+Yovi+y+quiero+saber+m%C3%A1s+de+tu+negocio+%F0%9F%99%82',
        whats: whatsapp,
        lat: coords ? coords.lat : '',
        lon: coords ? coords.lng : '',
      }));
    }
  }, []);
  return (
    <div className="call-to-action-btn">
      {ctaLink && cta && (
        <a href={ctaLink} target="_blank">
          {icons && cta && (<span dangerouslySetInnerHTML={{__html: icons[cta]}} ></span>)}
          <span>{cta}</span>
        </a>
      )}

      {!ctaLink && (
        <button type="button" onClick={() => setShowModal(true)}>
          {icons && cta && (<span dangerouslySetInnerHTML={{__html: icons[cta]}} ></span>)}
          <span>{cta}</span>
        </button>
      )}

      {showModal && (
        <CtaFormModal
          name={commerce.name}
          token={token}
          cta={cta}
          path={path}
          close={() => setShowModal(false)} 
        />
      )}
    </div>
  );
};

CallToAction.propTypes = {
  cta: PropTypes.string.isRequired,
  whatsapp: PropTypes.string,
  phone: PropTypes.string,
  coordinates: PropTypes.string,
  icons: PropTypes.objectOf(PropTypes.obj),
};

CallToAction.defaultProps = {
  whatsapp: '',
  phone: '',
  coordinates: '',
};

export default CallToAction;
