import React, {useState} from 'react';

const CommerceMore = ({icons, i18n}) => {
  const [showMoreOptions, setShowMoreOptions] = useState(false)

  return (
    <>
      <div className="commerce-header__information__area__social-media__item" 
      dangerouslySetInnerHTML={{ __html: icons.menu }}
      onClick={() => setShowMoreOptions(!showMoreOptions)}
      ></div>
      {
        showMoreOptions && 
        <div className="commerce-header__container">
          <div className="commerce-header__more-options">
            <div className="commerce-header__more-options__options">
              <div className="commerce-header__more-options__options__option">
                <div className="commerce-header__more-options__options__option__icon" 
                dangerouslySetInnerHTML={{ __html: icons.share }}
                ></div>
                <p className="commerce-header__more-options__options__option__text">{ i18n.share }</p>
              </div>
              <div className="commerce-header__more-options__options__option">
                <div className="commerce-header__more-options__options__option__icon" 
                dangerouslySetInnerHTML={{ __html: icons.info }}
                ></div>
                <p className="commerce-header__more-options__options__option__text">{ i18n.reports }</p>
              </div>
            </div>
            <div className="commerce-header__more-options__close" 
            dangerouslySetInnerHTML={{ __html: icons.close }}
            onClick={() => setShowMoreOptions(false)}
            ></div>
          </div>
        </div>
      }
    </>
  );
}

export default CommerceMore;
