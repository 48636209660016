import PropTypes from "prop-types";
import React, { useState } from "react";

const ShowMore = ({ initial, content }) => {
  const [show, setShow] = useState(initial);

  return (
    <>
      <div className={show ? 'full-content' : 'half-content'}>
        {content}
      </div>

      <button onClick={() => setShow(!show)}>
        <span>
          {show ? 'Ver menos' : 'Ver más'}
        </span>
      </button>
    </>
  )
};

ShowMore.propTypes = {
  initial: PropTypes.bool,
  content: PropTypes.string.isRequired,
};

ShowMore.defaultProps = {
  initial: false,
};

export default ShowMore;
