import React from 'react';
import PropTypes from 'prop-types';

const lensIcon = (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.41602 11.666C9.31551 11.666 11.666 9.31551 11.666 6.41602C11.666 3.51652 9.31551 1.16602 6.41602 1.16602C3.51652 1.16602 1.16602 3.51652 1.16602 6.41602C1.16602 9.31551 3.51652 11.666 6.41602 11.666Z" stroke="#FD495C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12.8333 12.8333L10.5 10.5" stroke="#FD495C" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
);


const SearchInput = ({ placeholder, setCategoryInput }) => {

    const handleCategoryChange = ({ target }) => {
        setCategoryInput(target.value);
    }

    return (
        <div className="category-header__search">
            { lensIcon }
            <input
                type="text"
                className="input-search"
                placeholder={ placeholder }
                onChange={ handleCategoryChange }
            />
        </div>
    )
}

SearchInput.propTypes = {

}

export default SearchInput
