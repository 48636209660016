import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MobileDropdownList from './MobileDropdownList';

const arroDown = (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.99928 5.17266L11.9493 0.222656L13.3633 1.63666L6.99928 8.00066L0.635286 1.63666L2.04929 0.222656L6.99928 5.17266Z" fill="#FD495C" />
  </svg>
);

const DualDropdown = ({ current, categories, subcategories }) => {
  const [displayDropdown, setDisplayDropdown] = useState(false);
  const [dropType, setDropType] = useState('categorías');

  const { category: ctgy, subcategory: sub } = current;

  const handleDropType = (type) => {
    if (type === dropType) {
      setDisplayDropdown(false);
    } else {
      setDropType(type);
    }
  };

  return (
    <div className={`dual-dropdown ${displayDropdown ? '--fixed' : ''}`}>
      {!displayDropdown && (
        <div className="dual-dropdown__top-bar">
          <button type="button" onClick={() => setDisplayDropdown(true)}>
            {sub.name}
            {' '}
            {arroDown}
          </button>
        </div>
      )}

      {displayDropdown && (
        <>
          <div className="dual-dropdown__select">
            <button
              type="button"
              className={dropType === 'categorías' ? '--selected' : ''}
              onClick={() => handleDropType('categorías')}
            >
              Categorías
            </button>

            <button
              type="button"
              className={dropType === 'categorías' ? '' : '--selected'}
              onClick={() => handleDropType('giros')}
            >
              Giros
            </button>
          </div>

          <p className="dual-dropdown__title">
            Lista de
            {' '}
            {dropType}
          </p>

          <MobileDropdownList
            title={dropType === 'categorías' ? 'Todas las categorías' : `Giros de ${ctgy.name}`}
            listItems={dropType === 'categorías' ? categories : subcategories}
            current={dropType === 'categorías' ? ctgy : sub}
          />
        </>
      )}

    </div>
  );
};

DualDropdown.propTypes = {
  current: PropTypes.objectOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  subcategories: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default DualDropdown;
