import React, { useRef } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";

// Import Swiper styles
import "swiper/swiper-bundle.css";

const arrowRight = (
  <svg
    width="7"
    height="10"
    viewBox="0 0 7 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.324707 8.81667L4.14137 5L0.324707 1.175L1.49971 0L6.49971 5L1.49971 10L0.324707 8.81667Z"
      fill="#262626"
    />
  </svg>
);

const SwipeControl = ({ back, refer }) => {
  const swiper = useSwiper();
  return (
    <button
      ref={refer}
      onClick={() => (back ? swiper.slidePrev() : swiper.slideNext())}
      className="d-none"
    />
  );
};

const GrandstandVoice = ({ data, showMore }) => {
  const slideBack = useRef();
  const slideForward = useRef();

  const redirectTo = (path) => {
    window.location = path;
  };
  return (
    <section className="grandstand-voice">
      <div className="grandstand-voice__header">
        <h2 className="grandstand-voice__header__title">Las voces de GRADA</h2>
        <div className="grandstand-voice__header__controls">
          <button
            className="--invert-x swiper-control"
            onClick={() => slideBack.current.click()}
          >
            {arrowRight}
          </button>
          <button
            className="swiper-control"
            onClick={() => slideForward.current.click()}
          >
            {arrowRight}
          </button>
        </div>
      </div>
      <ul className="grandstand-voice__list">
        {data.slice(0, 1).map(({ title, author, image, date, url, username }) => (
          <a key={username+title} href={url} className="grandstand-voice__card">
            <li className="grandstand-voice__card__content">
              <div className="card__image">
                <img src={image} alt={title} />
              </div>
              <p className="card__author" onClick={() => redirectTo(`/autor/${username}`) }>{author}</p>
              <h3 className="card__title">{title}</h3>
              <p className="card__date">{date}</p>
            </li>
          </a>
        ))}

        {data.slice(1, 2).map(({ title, author, image, date, url, username }) => (
          <a key={username+title} href={url} className="grandstand-voice__card">
            <li className="grandstand-voice__card__content">
              <div className="card__image">
                <img src={image} alt={title} />
              </div>
              <h3 className="card__title">{title}</h3>
              <p className="card__author" onClick={() => redirectTo(`/autor/${username}`) }>{author}</p>
              <p className="card__date">{date}</p>
            </li>
          </a>
        ))}

        <div className="grandstand-voice__list__slide">
          {data.slice(2, 6).map(({ title, author, image, date, url, username }) => (
            <a key={username+title} href={url} className="grandstand-voice__list__slide__card">
              <li>
                <div className="card__image">
                  <img src={image} alt={title} />
                </div>
                <h3 className="card__title">{title}</h3>
                <p className="card__author" onClick={() => redirectTo(`/autor/${username}`) }>{author}</p>
                <p className="card__date">{date}</p>
              </li>
            </a>
          ))}
        </div>
      </ul>

      <Swiper slidesPerView={"auto"} className="grandstand-voice__swiper">
        <SwipeControl refer={slideBack} back />
        <SwipeControl refer={slideForward} />
        {data.map(({ title, author, image, url, username }) => (
          <SwiperSlide key={title}>
            <a href={url} className="card">
              <div className="card__image">
                <img src={image} alt={title} />
              </div>
              <p className="card__author" onClick={() => redirectTo(`/autor/${username}`) }>{author}</p>
              <h3 className="card__title">{title}</h3>
            </a>
          </SwiperSlide>
        ))}
      </Swiper>

      <a href={showMore} className="btn-primary more-news">
        Ver más opiniones
      </a>
    </section>
  );
};

export default GrandstandVoice;
