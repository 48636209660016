import React, { useState } from 'react';
import PropTypes from 'prop-types';

const priceIcon = (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00391 15.5C3.86166 15.5 0.503906 12.1423 0.503906 8C0.503906 3.85775 3.86166 0.5 8.00391 0.5C12.1462 0.5 15.5039 3.85775 15.5039 8C15.5039 12.1423 12.1462 15.5 8.00391 15.5ZM5.37891 9.5V11H7.25391V12.5H8.75391V11H9.50391C10.0012 11 10.4781 10.8025 10.8297 10.4508C11.1814 10.0992 11.3789 9.62228 11.3789 9.125C11.3789 8.62772 11.1814 8.15081 10.8297 7.79918C10.4781 7.44755 10.0012 7.25 9.50391 7.25H6.50391C6.40445 7.25 6.30907 7.21049 6.23874 7.14017C6.16841 7.06984 6.12891 6.97446 6.12891 6.875C6.12891 6.77555 6.16841 6.68016 6.23874 6.60984C6.30907 6.53951 6.40445 6.5 6.50391 6.5H10.6289V5H8.75391V3.5H7.25391V5H6.50391C6.00663 5 5.52971 5.19755 5.17808 5.54918C4.82645 5.90081 4.62891 6.37772 4.62891 6.875C4.62891 7.37228 4.82645 7.8492 5.17808 8.20083C5.52971 8.55246 6.00663 8.75 6.50391 8.75H9.50391C9.60336 8.75 9.69875 8.78951 9.76907 8.85984C9.8394 8.93016 9.87891 9.02555 9.87891 9.125C9.87891 9.22446 9.8394 9.31984 9.76907 9.39017C9.69875 9.46049 9.60336 9.5 9.50391 9.5H5.37891Z" fill="#FD495C" />
  </svg>
);

const PriceRange = ({ current, submit }) => {
  const [price, setPrice] = useState(current);

  const handlePricing = (e) => {
    const { value } = e.target;

    if (value !== price) {
      setPrice(value);
    } else {
      setPrice(value - 1);
      e.target.checked = false
    }

    if (submit) {
      submit(e.target)
    };
  };

  return (
    <div className="price-range">
      <p>Precios</p>

      {[1, 2, 3, 4].map((tier) => (
        <label className={price >= tier ? '' : '--deselect'} key={tier}>
          <input
            type="radio"
            name="pricing"
            value={tier}
            onClick={(e) => handlePricing(e)}
          />
          { priceIcon }
        </label>
      ))}

    </div>
  );
};

PriceRange.propTypes = {
  current: PropTypes.string,
};

PriceRange.defaultProps = {
  current: '0',
};

export default PriceRange;
