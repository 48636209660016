import React, { useEffect, useState } from 'react'
import CategoryCard from '../components/CategoryCard';

const SubCategory = ({ categoriesRails }) => {
    const [showAll, setShowAll] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const sortCategories = categoriesRails.sort((a, b) => (
            b.commerces - a.commerces
        ));
        if (showAll) {
            setCategories(sortCategories);
        } else {
            setCategories(sortCategories.slice(0, 20));
        }
    }, [showAll])

    return (
        <>
            <div className="category-body">
                {
                    
                    categories.length > 0 ?
                        <div className="category-body__cards">
                            {
                                categories.map(category => (
                                    <CategoryCard
                                        key={ category.name }
                                        url={ category.url }
                                        name={ category.name }
                                        iconUrl={ category.iconUrl }
                                    />
                                ))
                            }
                        </div>
                        :
                        <h2 className="category-body__not-found">Categoría no encontrada</h2>
                    
                }
                {
                    categories.length !== 0 && categories.length > 19 && categoriesRails.length > 20 &&
                        <div className="category-body__show-button" onClick={() => { setShowAll(!showAll)} }>
                            <p>{ showAll ? 'Ver menos' : 'Ver más' }</p>
                            <svg width="14" className={showAll ? "rotate-180" : ""} height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.00072 5.17266L11.9507 0.222656L13.3647 1.63666L7.00072 8.00066L0.636719 1.63666L2.05072 0.222656L7.00072 5.17266Z" fill="#FD495C"/>
                            </svg>
                        </div>

                }
                <div className="category-body__poster">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </>
    )
}

SubCategory.propTypes = {

}

export default SubCategory
