import React, {useEffect} from "react";

const LoadGoogleAd = ({ adId, adClass }) => {
  // display google ad after component renders
  useEffect(() => {
    window.googletag = window.googletag || {cmd: []};

    googletag.cmd.push(function() { googletag.display(adId); });
  }, []);
  
  return (
    <div id={adId} className={adClass} />
  )
};

export default LoadGoogleAd
