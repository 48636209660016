import React, { useState } from 'react';
import PropTypes from 'prop-types';

const CategoryList = ({ current, categories, icons }) => {
  const { arrowDown, listIcon } = icons;
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <p id="categories" className="directory-filters__title">
        <img src={listIcon} alt="" />
        Categorías
      </p>

      <ul aria-labelledby="categories">
        <li>
          <a href={current.url} className="--current-category">
            {current.name}
          </a>
        </li>

        {categories.slice(0, showMore ? 30 : 10).map((ctgy) => (
          <li key={ctgy.name}>
            <a href={ctgy.url}>
              {ctgy.name}
            </a>
          </li>
        ))}

        <li className="show-btn">
          <button type="button" onClick={() => setShowMore(!showMore)}>
            {showMore ? 'Mostrar menos' : 'Mostrar más'}
            <img src={arrowDown} alt="" className={showMore ? '--invert-y' : ''} />
          </button>
        </li>
      </ul>

    </>
  );
};

CategoryList.propTypes = {
  current: PropTypes.shape({
    name: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  icons: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default CategoryList;
