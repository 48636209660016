import React, { useEffect, useRef, useState } from "react";

const share = (
  <svg
    width="13"
    height="14"
    viewBox="0 0 13 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M10.5 9.72065C9.99333 9.72065 9.54 9.92065 9.19333 10.234L4.44 7.46732C4.47333 7.31398 4.5 7.16065 4.5 7.00065C4.5 6.84065 4.47333 6.68732 4.44 6.53398L9.14 3.79398C9.5 4.12732 9.97333 4.33398 10.5 4.33398C11.0304 4.33398 11.5391 4.12327 11.9142 3.7482C12.2893 3.37313 12.5 2.86442 12.5 2.33398C12.5 1.80355 12.2893 1.29484 11.9142 0.919771C11.5391 0.544698 11.0304 0.333984 10.5 0.333984C9.96957 0.333984 9.46086 0.544698 9.08579 0.919771C8.71071 1.29484 8.5 1.80355 8.5 2.33398C8.5 2.49398 8.52667 2.64732 8.56 2.80065L3.86 5.54065C3.5 5.20732 3.02667 5.00065 2.5 5.00065C1.96957 5.00065 1.46086 5.21136 1.08579 5.58644C0.710714 5.96151 0.5 6.47022 0.5 7.00065C0.5 7.53108 0.710714 8.03979 1.08579 8.41487C1.46086 8.78994 1.96957 9.00065 2.5 9.00065C3.02667 9.00065 3.5 8.79398 3.86 8.46065L8.60667 11.2273C8.57333 11.3673 8.55333 11.514 8.55333 11.6673C8.55333 12.7407 9.42667 13.6073 10.5 13.6073C11.5733 13.6073 12.4467 12.7407 12.4467 11.6673C12.4467 11.151 12.2416 10.6559 11.8765 10.2908C11.5114 9.92575 11.0163 9.72065 10.5 9.72065Z" />
  </svg>
);

const close = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="#262626"
    />
  </svg>
);

const copy = (
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6673 13.9993H4.33398V4.66602H11.6673M11.6673 3.33268H4.33398C3.98036 3.33268 3.64122 3.47316 3.39118 3.72321C3.14113 3.97326 3.00065 4.31239 3.00065 4.66602V13.9993C3.00065 14.353 3.14113 14.6921 3.39118 14.9422C3.64122 15.1922 3.98036 15.3327 4.33398 15.3327H11.6673C12.0209 15.3327 12.3601 15.1922 12.6101 14.9422C12.8602 14.6921 13.0007 14.353 13.0007 13.9993V4.66602C13.0007 4.31239 12.8602 3.97326 12.6101 3.72321C12.3601 3.47316 12.0209 3.33268 11.6673 3.33268ZM9.66732 0.666016H1.66732C1.3137 0.666016 0.974557 0.806491 0.724509 1.05654C0.47446 1.30659 0.333984 1.64573 0.333984 1.99935V11.3327H1.66732V1.99935H9.66732V0.666016Z"
      fill="#A98F6F"
    />
  </svg>
);

const whatsapp = (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.25" width="48" height="48" rx="24" fill="#4CD360" />
    <path
      d="M24.3044 10.666C17.0244 10.666 11.091 16.5993 11.091 23.8793C11.091 26.2127 11.7044 28.4793 12.851 30.4793L10.9844 37.3327L17.9844 35.4927C19.9177 36.546 22.091 37.106 24.3044 37.106C31.5844 37.106 37.5177 31.1727 37.5177 23.8927C37.5177 20.3593 36.1444 17.0393 33.651 14.546C31.1577 12.0393 27.8377 10.666 24.3044 10.666ZM24.3177 12.8927C27.251 12.8927 29.9977 14.0393 32.0777 16.1193C34.1444 18.1993 35.291 20.9593 35.291 23.8927C35.291 29.946 30.3577 34.866 24.3044 34.866C22.331 34.866 20.3977 34.346 18.7177 33.3327L18.3177 33.106L14.1577 34.1993L15.2644 30.146L14.9977 29.7193C13.9044 27.9993 13.3177 25.9593 13.3177 23.8793C13.331 17.826 18.251 12.8927 24.3177 12.8927ZM19.6244 17.7727C19.411 17.7727 19.051 17.8527 18.7444 18.186C18.451 18.5193 17.5844 19.3327 17.5844 20.946C17.5844 22.5727 18.771 24.1327 18.9177 24.3593C19.1044 24.586 21.2644 27.9193 24.5844 29.3327C25.371 29.6927 25.9844 29.8927 26.4644 30.0393C27.251 30.2927 27.971 30.2527 28.5444 30.1727C29.1844 30.0793 30.491 29.3727 30.771 28.5993C31.051 27.826 31.051 27.1727 30.971 27.026C30.8777 26.8927 30.6644 26.8127 30.331 26.666C29.9977 26.4793 28.371 25.6793 28.0777 25.5727C27.771 25.466 27.5844 25.4127 27.331 25.7327C27.1177 26.066 26.4777 26.8127 26.291 27.026C26.091 27.2527 25.9044 27.2793 25.5844 27.1193C25.2377 26.946 24.171 26.5993 22.9177 25.4793C21.931 24.5993 21.2777 23.5193 21.0777 23.186C20.9177 22.866 21.0644 22.666 21.2244 22.5193C21.371 22.3727 21.5844 22.1327 21.7177 21.9327C21.891 21.746 21.9444 21.5993 22.051 21.386C22.1577 21.1593 22.1044 20.9727 22.0244 20.8127C21.9444 20.666 21.2777 19.0127 20.9977 18.3593C20.731 17.7193 20.4644 17.7993 20.251 17.786C20.0644 17.786 19.851 17.7727 19.6244 17.7727Z"
      fill="white"
    />
  </svg>
);

const facebook = (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.75" width="48" height="48" rx="24" fill="#405A9A" />
    <path
      d="M24.7513 10.7188C17.418 10.7188 11.418 16.7054 11.418 24.0788C11.418 30.7454 16.298 36.2787 22.6713 37.2787V27.9454H19.2846V24.0788H22.6713V21.1321C22.6713 17.7854 24.658 15.9454 27.7113 15.9454C29.1646 15.9454 30.6846 16.1988 30.6846 16.1988V19.4921H29.0046C27.3513 19.4921 26.8313 20.5188 26.8313 21.5721V24.0788H30.538L29.938 27.9454H26.8313V37.2787C29.9732 36.7825 32.8342 35.1794 34.8979 32.7588C36.9615 30.3382 38.0918 27.2596 38.0846 24.0788C38.0846 16.7054 32.0846 10.7188 24.7513 10.7188Z"
      fill="white"
    />
  </svg>
);

const twitter = (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.25" width="48" height="48" rx="24" fill="#40A2F5" />
    <path
      d="M38.1941 15.9987C37.1674 16.4654 36.0608 16.772 34.9141 16.9187C36.0874 16.212 36.9941 15.092 37.4208 13.7454C36.3141 14.412 35.0874 14.8787 33.7941 15.1454C32.7408 13.9987 31.2608 13.332 29.5808 13.332C26.4474 13.332 23.8874 15.892 23.8874 19.052C23.8874 19.5054 23.9408 19.9454 24.0341 20.3587C19.2874 20.1187 15.0608 17.8387 12.2474 14.3854C11.7541 15.2254 11.4741 16.212 11.4741 17.252C11.4741 19.2387 12.4741 20.9987 14.0208 21.9987C13.0741 21.9987 12.1941 21.732 11.4208 21.332C11.4208 21.332 11.4208 21.332 11.4208 21.372C11.4208 24.1454 13.3941 26.4654 16.0074 26.9854C15.5274 27.1187 15.0208 27.1854 14.5008 27.1854C14.1408 27.1854 13.7808 27.1454 13.4341 27.0787C14.1541 29.332 16.2474 31.012 18.7674 31.052C16.8208 32.5987 14.3541 33.5054 11.6608 33.5054C11.2074 33.5054 10.7541 33.4787 10.3008 33.4254C12.8341 35.052 15.8474 35.9987 19.0741 35.9987C29.5808 35.9987 35.3541 27.2787 35.3541 19.7187C35.3541 19.4654 35.3541 19.2254 35.3408 18.972C36.4608 18.172 37.4208 17.1587 38.1941 15.9987Z"
      fill="white"
    />
  </svg>
);

const embed = (
  <svg
    width="49"
    height="48"
    viewBox="0 0 49 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.75"
      width="48"
      height="48"
      rx="24"
      fill="#E1E1E1"
      fill-opacity="0.24"
    />
    <path
      d="M28.2141 30.1333L34.3474 24L28.2141 17.8667L30.0807 16L38.0807 24L30.0807 32L28.2141 30.1333ZM21.2807 30.1333L15.1474 24L21.2807 17.8667L19.4141 16L11.4141 24L19.4141 32L21.2807 30.1333Z"
      fill="#575757"
    />
  </svg>
);

const Share = ({ duration }) => {
  const title = document.title;
  const baseUrl = document.querySelector("link[rel=canonical]")
    ? document.querySelector("link[rel=canonical]").href
    : document.location.href;

  const [showShare, setShowShare] = useState(false);
  const [showEmbed, setShowEmbed] = useState(false);

  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [timeUse, setTimeUse] = useState(false);

  const [url, setUrl] = useState(baseUrl);
  const [embedUrl, setEmbedUrl] = useState(
    `${location.origin}/embed${baseUrl.split(location.origin)[1]}`
  );

  const handleClick = () => {
    if (navigator.share) {
      navigator
        .share({ title, url })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      setShowShare(true);
    }
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(url);
  };

  // handleStartTime
  useEffect(() => {
    // if (!timeCheckbox.current) return

    const time = minutes * 60 + seconds * 1;

    if (timeUse && time > 0) {
      setEmbedUrl(
        `${location.origin}/embed${baseUrl.split(location.origin)[1]}`
      );
      setUrl(`${baseUrl}?t=${time}`);
    } else {
      setEmbedUrl(
        `${location.origin}/embed${baseUrl.split(location.origin)[1]}`
      );
      setUrl(baseUrl);
    }
  }, [seconds, minutes, timeUse]);

  return (
    <>
      {showShare && (
        <div className="cta-overlay">
          <div className="share-box">
            <h3>
              Compartir video
              <button onClick={() => setShowShare(false)}>{close}</button>
            </h3>

            <div className="share-socials">
              <a
                href={`https://api.whatsapp.com/send?text=${url}`}
                target="_blank"
              >
                {whatsapp}
                Whatsapp
              </a>

              <a
                href={`https://www.facebook.com/sharer.php?u=${url}`}
                target="_blank"
              >
                {facebook}
                Facebook
              </a>

              <a
                href={`https://twitter.com/intent/tweet?url=${url}`}
                target="_blank"
              >
                {twitter}
                Twitter
              </a>

              <button onClick={() => setShowEmbed(!showEmbed)}>
                {embed}
                Embed
              </button>
            </div>

            {showEmbed && (
              <div className="share-url">
                <code>
                  {`
                    <iframe
                      width="560"
                      height="336"
                      src="${embedUrl}"
                      title="${title}"
                      frameborder="0"
                      allowfullscreen></iframe>
                  `}
                </code>
              </div>
            )}

            <p>ó compartir por enlace</p>

            <div className="share-url">
              <span>{url}</span>
              <button onClick={handleCopyUrl}>
                {copy}
                Copiar
              </button>
            </div>

            {!duration && (
              <div className="start-at">
                <input
                  type="checkbox"
                  onChange={({ target }) => setTimeUse(target.checked)}
                />
                Comenzar video en:
                <div className="time-select">
                  <input
                    type="number"
                    value={minutes}
                    onChange={({ target }) => setMinutes(target.value)}
                    min={0}
                    max={duration / 60}
                  />
                  :
                  <input
                    type="number"
                    value={seconds}
                    onChange={({ target }) => setSeconds(target.value)}
                    min={0}
                    max={duration % 60}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      <button onClick={handleClick} className="share-button">
        {share}
        <span>Compartir</span>
      </button>
    </>
  );
};

export default Share;
