import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const ArticleTypeFilter = ({ article_types }) => {
  const [searchInput, setSearchInput] = useState('');
  const [urlSearch, setUrlSearch] = useState({
    article_type: ''
  });

  const [originalUrlParams, setOriginalUrlParams] = useState({
    article_type: ''
});

  useEffect(() => {
    // Get url path on load page
    const newUrlSearch = window.location.search
    if (newUrlSearch) {
        const temporalUrlSearch = newUrlSearch.slice(1).split('&');
        temporalUrlSearch.forEach(item => {
            const itemArray = item.split('=')
            
            if (urlSearch.hasOwnProperty(itemArray[0])) {
                setUrlSearch(urlSearch => ({
                    ...urlSearch,
                    [itemArray[0]]: itemArray[1]
                }));
                setOriginalUrlParams(urlSearch => ({
                    ...urlSearch,
                    [itemArray[0]]: itemArray[1]
                }));
            }
        });
    }
  }, []);

  useEffect(() => {
    if (originalUrlParams) {
        if ( originalUrlParams.article_type != urlSearch.article_type ) {
          const newUrlParams = `${window.location.origin}${window.location.pathname}?${generateUrlParams()}`;
          window.location.href = newUrlParams;
        }
    }
}, [urlSearch]);

  const generateUrlParams = () => {
    const newUrlParams = {}
    if (urlSearch.article_type) {
        newUrlParams['article_type'] = urlSearch.article_type;
    }

    const urlParameters = Object.entries(newUrlParams).map(e => e.join('=')).join('&');
    return urlParameters
  }

  const selectArticleType = (id) => {
    setUrlSearch(urlSearch => ({
      ...urlSearch,
      article_type: id
    }));
  }
  console.log(urlSearch)

  return (
    <ul className="navigation-area">
      {
        article_types.map((article_type) => (
          <li
            onClick={ () => selectArticleType(article_type.id) }
            className={`
              navigation-area__item 
              ${ urlSearch.article_type == article_type.id ? '--active' : ''}
            `}
            key={article_type.id}
          >{ article_type.name }</li>
        ))
      }
    </ul>
  )
}

ArticleTypeFilter.propTypes = {}

export default ArticleTypeFilter