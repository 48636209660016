import React from 'react';

const ProcedureCard = ({images, ministry, name, i18nCard}) => {
  return (
    <div className="procedure__cards__card">
      <div className="procedure__cards__card__image">
        <img src={images.main} className="procedure__cards__card__image__main"  alt={ministry}/>
        <div className="procedure__cards__card__logo">
          <img src={images.logo} alt={ministry}></img>
        </div>
        <p className="procedure__cards__card__ministry">{ministry}</p>
      </div>
      <div className="procedure__cards__card__information">
        <h3 className="procedure__cards__card__information__name">{ name }</h3>
        <button className="procedure__cards__card__information__button">{ i18nCard.button }</button>
      </div>
    </div>
  );
}

export default ProcedureCard;
