import React, { useState, useEffect } from 'react'

const useEventFilters = () => {
    const [urlSearch, setUrlSearch] = useState({
        category: '',
        date: '',
        price: '',
        online_sales: '',
        search: '',
        price_between: '',
        order: '',
    });

    const [searchInput, setSearchInput] = useState('');
    const [inputSearchSubmited, setInputSearchSubmited] = useState(false);

    const [originalUrlParams, setOriginalUrlParams] = useState({
        category: '',
        date: '',
        price: '',
        online_sales: '',
        search: '',
        price_between: '',
        order: '',
    });

    useEffect(() => {
        // Get url path on load page
        const newUrlSearch = window.location.search
        if (newUrlSearch) {
            const temporalUrlSearch = newUrlSearch.slice(1).split('&');
            temporalUrlSearch.forEach(item => {
                const itemArray = item.split('=')
                
                if (urlSearch.hasOwnProperty(itemArray[0])) {
                    setUrlSearch(urlSearch => ({
                        ...urlSearch,
                        [itemArray[0]]: itemArray[1]
                    }));
                    setOriginalUrlParams(urlSearch => ({
                        ...urlSearch,
                        [itemArray[0]]: itemArray[1]
                    }));
                    setSearchInput(urlSearch.search);
                }
            });
        }
        
    }, []);


    useEffect(() => {
        if (originalUrlParams) {
            if (
                originalUrlParams.category != urlSearch.category ||
                originalUrlParams.date != urlSearch.date ||
                originalUrlParams.price != urlSearch.price  ||
                originalUrlParams.online_sales != urlSearch.online_sales ||
                originalUrlParams.price_between != urlSearch.price_between ||
                originalUrlParams.order != urlSearch.order,
                originalUrlParams.search != urlSearch.search
            ) {
                const newUrlParams = `${window.location.origin}${window.location.pathname}?${generateUrlParams()}`;
                window.location.href = newUrlParams;
            }
        }
    }, [urlSearch]);

    useEffect(() => {
        if (inputSearchSubmited == true) {
            setUrlSearch({
                ...urlSearch,
                search: searchInput
            });
        }
    }, [inputSearchSubmited])

    const generateUrlParams = () => {
        const newUrlParams = {}
        if (urlSearch.category) {
            newUrlParams['category'] = urlSearch.category;
        }
        if (urlSearch.date) {
            newUrlParams['date'] = urlSearch.date;
        }
        if (urlSearch.price) {
            newUrlParams['price'] = urlSearch.price;
        }
        if (urlSearch.online_sales) {
            newUrlParams['online_sales'] = urlSearch.online_sales;
        }
        if (urlSearch.search) {
            newUrlParams['search'] = urlSearch.search;
        }
        if (urlSearch.price_between) {
            newUrlParams['price_between'] = urlSearch.price_between;
        }
        if (urlSearch.order) {
            newUrlParams['order'] = urlSearch.order;
        }

        const urlParameters = Object.entries(newUrlParams).map(e => e.join('=')).join('&');
        return urlParameters
    }

    const searchEnter = (e) => {
        if(e.key === 'Enter'){
            e.preventDefault();
            console.log('aaa')
            setUrlSearch({
                ...urlSearch,
                search: searchInput
            });
            setInputSearchSubmited(true);
        }
    }

    const selectOption = (target) => {
        if (urlSearch.hasOwnProperty(target.name)) {
            setUrlSearch(urlSearch => ({
                ...urlSearch,
                [target.name]: target.value
            }));
        }
    }

    const cleanOption = (name) => {
        setUrlSearch(urlSearch => ({
            ...urlSearch,
            [name]: ''
        }));
    }

    const checkOnlineSales = (target) => {
        if (target.checked) {
            setUrlSearch(urlSearch => ({
                ...urlSearch,
                online_sales: 'true'
            }));
        } else {
            setUrlSearch(urlSearch => ({
                ...urlSearch,
                online_sales: ''
            }));
        }
    }

    // Data
    const eventPrices = [
        {
            name: 'Menos de 300$',
            value: '300'
        },{
            name: 'Entre 300$ y 400$',
            value: '300-400'
        },{
            name: 'Entre 400$ y 650$',
            value: '400-650'
        },{
            name: 'Entre 650$ y 900$',
            value: '650-900'
        },{
            name: 'Mas de 900$',
            value: '900'
        }
    ];

    const eventDates = [
        {
            name: 'En 1 día',
            value: '1'
        },{
            name: 'En 2 dias',
            value: '2'
        },{
            name: 'En 3 dias',
            value: '3'
        },{
            name: 'En 4 dias',
            value: '4'
        },{
            name: 'En 5 dias',
            value: '5'
        }
    ];

    const eventOrders = [
        {
            name: 'Fecha',
            value: 'date'
        },{
            name: 'Nombre',
            value: 'name'
        },
    ]

    return {
        urlSearch,
        searchEnter,
        searchInput,
        selectOption,
        checkOnlineSales,
        eventPrices,
        cleanOption,
        eventDates,
        eventOrders,
        setSearchInput,
    };
}

export { useEventFilters }
