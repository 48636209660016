import React from 'react';
import PropTypes from 'prop-types';
import { Slide } from 'react-slideshow-image';

const LowTierSlideShow = ({ commerces, arrowIcon }) => {
  const properties = {
    duration: 3000,
    slidesToShow: 4,
    slidesToScroll: 2,
    indicators: false,
    prevArrow: <button type="button" className="arrow-prev"><img src={arrowIcon} alt="Anterior" /></button>,
    nextArrow: <button type="button" className="arrow-next"><img src={arrowIcon} alt="Siguiente" /></button>,
  };

  return (
    <Slide easing="ease" {...properties}>
      {commerces.map((i) => (
        <a href={i.path} className="low-tier-commerce" key={i.name}>
          <img src={i.img} alt={i.name} />
          <h6>{i.name}</h6>
        </a>
      ))}
    </Slide>
  );
};

LowTierSlideShow.propTypes = {
  commerces: PropTypes.arrayOf(PropTypes.object).isRequired,
  arrowIcon: PropTypes.string.isRequired,
};

export default LowTierSlideShow;
