import React, { useState } from 'react';
import EventCards from '../../HomePage/components/EventCards';
import PropTypes from 'prop-types';

const EventFilter = ({ categories, dates, events }) => {
  const [category, setCategory] = useState('Todas');
  const [date, setDate] = useState('Todas');
  const [onlineTickets, setOnlineTickets] = useState(false);
  const [filterDisplay, setFilterDisplay] = useState(false);

  const handleFilterDisplay = () => {
    setFilterDisplay(!filterDisplay);
  };

  const handleReset = () => {
    setCategory('');
    setDate('');
    setOnlineTickets(false);
  }

  return (
    <div className="business-event">
      <div className="business-event__filters">
        <button
          onClick={handleFilterDisplay}
          className={'business-event__filters__toggle'}>
          Filtrar
        </button>

        <div className={
            `business-event__filters__backdrop ${filterDisplay ? '--shown' : '--hidden'}`
          }>

          <div className={
            `business-event__filters__fields ${filterDisplay ? '--shown' : '--hidden'}`
          }>
            <button
              className="business-event__filters__fields__close"
              onClick={handleFilterDisplay}>
            </button>

            <span>Ordenar por</span>

            <label for="date">Fecha</label>
            <select
              name="date"
              value={date}
              onChange={e => setDate(e.target.value)}
            >
              <option value="">Todas</option>

              {dates.map(date => (
                <option key={date}>
                  {date}
                </option>
              ))}
            </select>

            <label for="category">Categoría</label>
            <select
              name="category"
              value={category}
              onChange={e => setCategory(e.target.value)}
            >
              <option value="">Todas</option>

              {categories.map(category => (
                <option key={category}>
                  {category}
                </option>
              ))}
            </select>
            
            <label className="business-event__filters__fields__toggle">
              Mostrar solo Boletos Online

              <input
                name="tickets"
                type="checkbox"
                checked={onlineTickets}
                onClick={() => setOnlineTickets(!onlineTickets)}
              />
              <span className="business-event__filters__fields__toggle__switch"></span>
            </label>

            <div className="business-event__filters__fields__buttons">
              <button onClick={handleReset}>
                Restablecer
              </button>
              <button onClick={handleFilterDisplay}>
                Aceptar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="business-event__events">
        <h2>Próximos eventos</h2>
        <EventCards eventsDefault={events} />

        <h2>Eventos anteriores</h2>
        <EventCards eventsDefault={events} past />
      </div>
    </div>
  )
};

EventFilter.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  dates: PropTypes.arrayOf(PropTypes.string).isRequired,
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default EventFilter;