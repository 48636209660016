import React, { useEffect, useState } from "react";

const Printed = ({ printeds, size, icon, defaultCover, todayCover }) => {
  const [currentPrinted, setCurrentPrinted] = useState(0);
  const [printedData, setPrintedData] = useState(false);
  const { title, date } = printeds[currentPrinted];

  useEffect(() => {
    const getPrinted = async () => {
      const response = await fetch(
        `/search/printed/${printeds[currentPrinted]["id"]}`
      );
      const data = await response.json();
      setPrintedData(data);
    }

    getPrinted();
  }, [currentPrinted]);

  const formatDate = (timestamp) => {
    const dt = new Date(timestamp),
      date = dt.getDate(),
      diffDays = new Date().getDate() - date,
      diffMonths = new Date().getMonth() - dt.getMonth(),
      diffYears = new Date().getFullYear() - dt.getFullYear();

    if (diffYears === 0 && diffMonths === 0) {
      if (diffDays === 1) {
        return "Hoy";
      } else if (diffDays === 2) {
        return "Ayer";
      }
    }

    dt.setDate(date + 1);

    return dt.toLocaleDateString("es-ES", { day: "numeric", month: "long" });
  };

  return (
    <div className={`aside__newspaper ${size}`}>
      <div className="newspaper__header">
        <h3 className="header__title">El Impreso</h3>
        <div className="newspaper__navigator">
          <button
            onClick={() => setCurrentPrinted(currentPrinted + 1)}
            disabled={currentPrinted === printeds.length - 1}
            dangerouslySetInnerHTML={{ __html: icon }}
            className="--invert-x"
          />
          <button
            onClick={() => setCurrentPrinted(currentPrinted - 1)}
            dangerouslySetInnerHTML={{ __html: icon }}
            disabled={currentPrinted === 0}
          />
        </div>
      </div>
      <div className="newspaper__content">
        <img
          className="newspaper__image"
          src={(currentPrinted === 0 ? todayCover : printedData.cover) ?? defaultCover}
          width="235"
          height="350"
        />
        <p className="newspaper__date">{formatDate(date)}</p>
        <div className="newspaper__title">{title}</div>
        <a className="newspaper__button" href={printedData.pdf ?? "#"}>
          Leer número
        </a>
      </div>
    </div>
  );
};

export default Printed;
