import React from "react";

import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/swiper-bundle.css";

const VideoHeader = ({ videos }) => (
  <>
    <Swiper
      pagination={true}
      navigation={true}
      rewind={true}
      loop={true}
      autoplay={{delay: 4000}}
      modules={[Autoplay, Navigation, Pagination]}
      className="video-swiper"
    >
      {videos.map(({ cta, background, background_mobile }) => (
        <SwiperSlide key={cta}>
          <div className="card">
            <a href={cta} className="card__image">
              <img className="d-xl-none" src={background_mobile} />
              <img className="d-none d-xl-block" src={background} />
            </a>
            {/* <div className="card__content">
              <h3 className="card__content__title">{title}</h3>
              <span className="card__content__subtitle">{subtitle}</span>
              <p className="card__content__description">{description}</p>
              <a href={cta} className="card__content__cta">
                VER
              </a>
            </div> */}
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  </>
);

VideoHeader.propTypes = {};

export default VideoHeader;
