import React, { useState } from 'react';
import PropTypes from 'prop-types';
import buildUrl from '../helpers/ctaUrl';
import CtaFormModal from '../Directory/components/CtaFormModal';

const OptionsButton = ({ availableCta, data, icons }) => {
  const { name, phone, location, path, whats: { number, message } } = data;
  const coords = JSON.parse(location);

  const [showOptions, setShowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');

  const handleOverlay = e => {
    if (e.target.classList.contains('options-button__overlay')) {
      setShowOptions(false);
    };
  };

  const handleOptions = (title) => {
    setModalTitle(title)
    setShowOptions(false);
    setShowModal(true);
  };

  return (
    <>
      <button
        type="button"
        onClick={() => setShowOptions(!showOptions)}
        className="options-button"
      >
        <span dangerouslySetInnerHTML={{ __html: icons.dots }} />
      </button>

      {showOptions && (
        <div className="options-button__overlay" onClick={(e) => handleOverlay(e)}>
          <ul className="options-button__options">
            {availableCta.map(cta => {
              if (cta !== 'Cotizar' && cta !== 'Informes') {
                return (
                  <li>
                    <a href={buildUrl(cta, {
                      phone,
                      lat: coords ? coords.lat : '',
                      lon: coords ? coords.lng : '',
                      whats: number,
                      message,
                    })}
                    >
                      <span dangerouslySetInnerHTML={{ __html: icons[cta] }} />
                      {cta}
                    </a>
                  </li>
                )
              }

              return (
                <li>
                  <button type="button" onClick={() => handleOptions(cta)}>
                    <span dangerouslySetInnerHTML={{ __html: icons[cta] }} />
                    {cta}
                  </button>
                </li>
              )
            })}
          </ul>
        </div>
      )}

      {showModal && (
        <CtaFormModal name={name} cta={modalTitle} path={path} close={() => setShowModal(false)} />
      )}
    </>
  );
};

OptionsButton.propTypes = {
  availableCta: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.shape({
    name: PropTypes.string,
    phone: PropTypes.string,
    whats: PropTypes.shape({
      number: PropTypes.string,
      message: PropTypes.string,
    }),
    path: PropTypes.string,
    location: PropTypes.shape({
      lon: PropTypes.string,
      lat: PropTypes.string,
    }),
  }).isRequired,
  icons: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default OptionsButton;

//  name, cta, path, token, close