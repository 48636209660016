import React, { useState } from 'react'
import PropTypes from 'prop-types';
import ServiceCard from './ServiceCard';

const ServiceTabs = ({ categories, services }) => {
  const [btnSelected, setBtnSelected] = useState(categories[0]);
  services.sort(card => card.image ? -1 : 1)

  return (
    <div className="service-tabs">
      <div className="service-tabs__categories">
        {categories.map(category => (
          <button
            key={category}
            onClick={() => setBtnSelected(category)}
            className={category == btnSelected ? 'service-tabs--selected' : ''}
          >
            {category}
          </button>
        ))}
      </div>

      {services.map(service => (
        <ServiceCard service={service} key={service.title} popular />
      ))}
    </div>
  );
};

ServiceTabs.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.string).isRequired,
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ServiceTabs;
