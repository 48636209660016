import React from "react";
import propTypes from "prop-types";

const PictureTag = ({ desktopImg, mobileImg, defaultImg }) => {
  return (
    <picture>
      <source srcSet={mobileImg} media="(max-width: 768px)" />
      <source srcSet={desktopImg} media="(min-width: 769px)" />
      <img src={defaultImg} alt="" />
    </picture>
  )
};

export default PictureTag;
