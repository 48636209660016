import React, { useEffect, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import PropTypes from 'prop-types';
import { useWindowSize } from '../hooks/useWindowSize';

const EventCardsSlideShow = ({ events, icons, configuration }) => {
  const windowSize = useWindowSize();
  const { arrowIcon } = icons;

  const [properties, setProperties] = useState({
    duration: 30000,
    slidesToShow: configuration.slidesToShow.tablet,
    slidesToScroll: 2,
    indicators: true,
    prevArrow: <button type="button" className="arrow-prev"><img src={arrowIcon} alt="Anterior" /></button>,
    nextArrow: <button type="button" className="arrow-next"><img src={arrowIcon} alt="Siguiente" /></button>,
  });

  useEffect(() => {
    if (windowSize[0] < 980) {
      setProperties({ ...properties, slidesToShow: configuration.slidesToShow.tablet });
    } else {
      setProperties({
        ...properties,
        slidesToShow: configuration.slidesToShow.desktop,
        arrows: events.length > configuration.slidesToShow.desktop,
      });
    }
  }, [windowSize]);

  return (
    <>
      {
      windowSize[0] < 769 || events.length < configuration.slidesToShow.desktop
        ? (
          <div className="event-cards --mobile">
            {events.map((e) => {
              const rawDate = e.date?.trim()?.split('/');
              const date = new Date(`${rawDate[1]}/${Number(rawDate[0])}/${rawDate[2]}`);
              const displayDate = date.toLocaleDateString('es-ES', {
                day: 'numeric',
                month: 'short',
              });
              return (
                <a href={e.url} key={e.title} className="event-simple-card">
                  <div>
                    <div className="event-simple-card__image-wrapper">
                      <img className="event-simple-card__image-wrapper__image" src={e.img} alt={e.title} key={e.title} />
                      <p className="event-simple-card__image-wrapper__category">{ e.category }</p>
                    </div>
                    <h3 className="event-simple-card__title">{ e.title }</h3>
                  </div>
                  <p className="event-simple-card__date"><span className="event-simple-card__date__month">{displayDate}</span></p>
                </a>
              );
            })}
          </div>
        )
        : (
          <Slide easing="ease" {...properties}>
            {events.map((e) => {
              const rawDate = e.date?.trim()?.split('/');
              const date = new Date(`${rawDate[1]}/${Number(rawDate[0])}/${rawDate[2]}`);
              const displayDate = date.toLocaleDateString('es-ES', {
                day: 'numeric',
                month: 'short',
              });
              return (
                <a href={e.url} key={e.title} className="event-simple-card">
                  <div>
                    <div className="event-simple-card__image-wrapper">
                      <img className="event-simple-card__image-wrapper__image" src={e.img} alt={e.title} key={e.title} />
                      <p className="event-simple-card__image-wrapper__category">{ e.category }</p>
                    </div>
                    <h3 className="event-simple-card__title">{ e.title }</h3>
                  </div>
                  <p className="event-simple-card__date"><span className="event-simple-card__date__month">{displayDate}</span></p>
                </a>
              );
            })}
          </Slide>
        )
    }
    </>
  );
};

EventCardsSlideShow.propTypes = {
  events: PropTypes.arrayOf(PropTypes.object).isRequired,
  icons: PropTypes.objectOf(PropTypes.string).isRequired,
  configuration: PropTypes.object,
};

EventCardsSlideShow.defaultProps = {
  configuration: {
    duration: 100000,
    slidesToShow: {
      desktop: 4,
      tablet: 3,
    },
  },
};

export default EventCardsSlideShow;
