import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MobileFilterSelect from './MobileFilterSelect';
import MobileFilterSwitch from './MobileFilterSwitch';
import PriceRange from '../../Directory/components/PriceRange';

const EventMobileFilters = ({ icons }) => {
    const [showFilters, setShowFilters] = useState(false);
    
    return (
        <div className="mobile-filters">
            <div
                className="mobile-filters__button"
                onClick={() => setShowFilters(true)}
                >
                <span dangerouslySetInnerHTML={{__html: icons.filter}}></span>
                <p>Filtrar</p>
            </div>
            {
                showFilters &&
                    <div className="mobile-filters__fullscreen">
                        <div className="mobile-filters__fullscreen__header">
                            <div className="mobile-filters__fullscreen__header__title">
                                <span dangerouslySetInnerHTML={{__html: icons.filterSimple}}></span>
                                <p>Filtros</p>
                            </div>
                            <button
                                className="mobile-filters__fullscreen__header__close"
                                dangerouslySetInnerHTML={{__html: icons.close}}
                                onClick={() => setShowFilters(false)}
                                ></button>
                        </div>
                        <div className="mobile-filters__fullscreen__body">
                            <div className="mobile-filters__fullscreen__body__select-area">
                                <MobileFilterSelect name="Fecha" />
                                <MobileFilterSelect name="Categoria" />
                            </div>
                            <div className="mobile-filters__fullscreen__body__switch-area">
                                <MobileFilterSwitch />
                            </div>
                            <div className="mobile-filters__fullscreen__body__price-area">
                                <PriceRange />
                            </div>
                        </div>
                        <button
                            className="mobile-filters__fullscreen__footer"
                            type="button"
                        >Aplicar</button>
                    </div>
            }
        </div>
    )
}

EventMobileFilters.propTypes = {
    
}

export default EventMobileFilters
