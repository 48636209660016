import React from 'react';
import PropTypes from 'prop-types';
import FilterCheckbox from './FilterCheckbox';
import PriceRange from './PriceRange';

const DirectoryFilters = ({
  filters, subfilters, currentFilters, currentSubfilters, icon, pricing
}) => {

  const submitForm = (target) => {
    setTimeout(() => {
      target.form.submit();
    }, 2500);
  };
  
  return (
    <form>
      <PriceRange current={pricing} submit={submitForm} />
    
      <p id="general-filters" className="directory-filters__title">
        <img src={icon} alt='' />
        Filtros
      </p>
      <ul aria-labelledby="general-filters" className="directory-filters__list">
        {filters.map(f => (
          <li key={f.name}>
            <FilterCheckbox
              text={f.name}
              name='filters[]'
              value={f.id}
              current={currentFilters} 
              submit={submitForm}
            />
          </li>
        ))}
      </ul>

      {Object.keys(subfilters).map(s => (
        <div key={s}>
          <p id={s} className="directory-filters__title">{s}</p>
          <ul aria-labelledby={s} className="directory-filters__list">
            {subfilters[s].map(f => (
              <li key={f.name}>
                <FilterCheckbox
                  text={f.name}
                  name='subfilters[]'
                  value={f.id}
                  current={currentSubfilters} 
                  submit={submitForm}
                />
              </li>
            ))}
          </ul>
        </div>
      ))}
    </form>
  )
};

DirectoryFilters.propTypes = {
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  subfilters: PropTypes.object.isRequired,
  icon: PropTypes.string.isRequired,
  currentFilters: PropTypes.arrayOf(PropTypes.string),
  currentSubfilters: PropTypes.arrayOf(PropTypes.string),
  pricing: PropTypes.string,
}

export default DirectoryFilters;
