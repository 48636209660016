import React, { useState } from "react";
import Drawer from "./Drawer";

const btnIcon = (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 4.16705H1.33333V2.83372H0V4.16705ZM0 6.83372H1.33333V5.50039H0V6.83372ZM0 1.50039H1.33333V0.167053H0V1.50039ZM2.66667 4.16705H12V2.83372H2.66667V4.16705ZM2.66667 6.83372H12V5.50039H2.66667V6.83372ZM2.66667 0.167053V1.50039H12V0.167053H2.66667Z" />
  </svg>
);

const docIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.41 5.41L12.58 0.58C12.21 0.21 11.7 0 11.17 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V6.83C18 6.3 17.79 5.79 17.41 5.41ZM4 4H11V6H4V4ZM14 14H4V12H14V14ZM14 10H4V8H14V10Z"
      fill="#212529"
    />
  </svg>
);

const download = (
  <svg
    width="48"
    height="37"
    viewBox="0 0 48 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.3125"
      y="0.8125"
      width="47.375"
      height="35.375"
      rx="7.6875"
      fill="#D1E7DD"
    />
    <path
      d="M17 27H31V25H17V27ZM31 16H27V10H21V16H17L24 23L31 16Z"
      fill="#198754"
    />
    <rect
      x="0.3125"
      y="0.8125"
      width="47.375"
      height="35.375"
      rx="7.6875"
      stroke="#75B798"
      stroke-width="0.625"
    />
  </svg>
);

const Docs = ({ docs }) => (
  <Drawer btnIcon={btnIcon} title="Documentos">
    {docs.map((d) => (
      <div className="drawer__content__doc">
        <i>{docIcon}</i>
        <div>
          <p>{d.filename}</p>
          <p>Tamaño: {d.size}</p>
        </div>
        <a href={d.url} target="_blank">
          {download}
        </a>
      </div>
    ))}
  </Drawer>
);

export default Docs;
