const whatsAppLink = (number, message) => (`https://wa.me/52${number}?text=${message}`);

const phoneCallLink = (number) => (`tel:${number}`);

const mapsLink = (lat, lon) => (`http://www.google.com/maps/search/?api=1&query=${lat}%2C${lon}`);

const buildUrl = (cta, data) => {
  let href = '';

  if (cta === 'Llamar') {
    href = phoneCallLink(data.phone);
  } else if (cta === 'Ubicación') {
    href = mapsLink(data.lat, data.lon);
  } else {
    href = whatsAppLink(data.whats, data.message);
  }

  return href;
};

export default buildUrl;
