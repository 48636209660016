import React from "react";

const VotesTable = ({ votes }) => (
  <table className="drawer__content__table --alternative">
    <thead>
      <tr>
        <th></th>
        <th>Favor</th>
        <th>En Contra</th>
        <th>Abstención</th>
        <th>Total</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td></td>
        <td>2</td>
        <td>0</td>
        <td>1</td>
        <td>3</td>
      </tr>
      <tr>
        <td></td>
        <td>4</td>
        <td>1</td>
        <td>0</td>
        <td>5</td>
      </tr>
      <tr>
        <td></td>
        <td>1</td>
        <td>2</td>
        <td>1</td>
        <td>4</td>
      </tr>
      <tr>
        <td></td>
        <td>2</td>
        <td>1</td>
        <td>2</td>
        <td>5</td>
      </tr>
    </tbody>
  </table>
);

export default VotesTable;
