import React, { useState } from 'react';
import PropTypes from 'prop-types';

const ListToggler = ({ items, rows, itemName, toggle, buttonClass }) => {
  const [toggleList, setToggleList] = useState(toggle);
  const [showText, setShowText] = useState(toggle ? 'Ver menos' : 'Ver más');

  const handleToggle = () => {
    setShowText(toggleList ? 'Ver más' : 'Ver menos');
    setToggleList(!toggleList);
  };
  
  return (
    <>
      {items.slice(0, toggleList ? items.length : rows).map((i, index) => (
        <li dangerouslySetInnerHTML={{__html: i}} key={`list-${itemName}-${index}`} />
      ))}
        
      {items.length > rows && (
        <li>
          <button type="button" onClick={handleToggle} className={`list-toggler__button ${buttonClass}`}>
            {`${showText} ${itemName}`}
          </button>
        </li>
      )}
    </>
  )
};

ListToggler.propTypes = {
  items: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.number,
  itemName: PropTypes.string,
  toggle: PropTypes.bool,
  buttonClass: PropTypes.string,
};

ListToggler.defaultProps = {
  rows: 5,
  itemName: '',
  toggle: false,
  buttonClass: '',
};

export default ListToggler;
