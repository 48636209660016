import React from 'react';
import PropTypes from 'prop-types';

const closeIcon = (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.00072 5.58599L11.9507 0.635986L13.3647 2.04999L8.41472 6.99999L13.3647 11.95L11.9507 13.364L7.00072 8.41399L2.05072 13.364L0.636719 11.95L5.58672 6.99999L0.636719 2.04999L2.05072 0.635986L7.00072 5.58599Z" fill="#021D49"/>
  </svg>
)

const sendIcon = (
  <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 8.66674H4V7.3334H0V1.23074C9.91698e-06 1.17281 0.0151136 1.11589 0.0438223 1.06558C0.072531 1.01528 0.113854 0.973316 0.163718 0.943842C0.213582 0.914368 0.270265 0.898396 0.328182 0.897501C0.386099 0.896606 0.443249 0.910818 0.494 0.938737L12.802 7.70807C12.8543 7.73685 12.8978 7.77912 12.9282 7.83048C12.9586 7.88184 12.9746 7.94041 12.9746 8.00007C12.9746 8.05973 12.9586 8.1183 12.9282 8.16966C12.8978 8.22102 12.8543 8.2633 12.802 8.29207L0.494 15.0614C0.443249 15.0893 0.386099 15.1035 0.328182 15.1026C0.270265 15.1017 0.213582 15.0858 0.163718 15.0563C0.113854 15.0268 0.072531 14.9849 0.0438223 14.9346C0.0151136 14.8842 9.91698e-06 14.8273 0 14.7694V8.66674Z" fill="#FD495C"/>
  </svg>
)

const CtaFormModal = ({ name, cta, path, token, close }) => {
  const title = cta === 'Cotizar' ? 'cotización' : 'informes' 

  return (
    <div className="cta-overlay">
      <form className="cta-request" method="post" action={path}>
        <input type="hidden" name="authenticity_token" value={token} />
        <input type="hidden" name="title" value={cta} />
      
        <button 
          type="button"
          onClick={close}
          className="cta-request__close"
        >
          {closeIcon}
        </button>
        <h4>Pedir {title} a: <span>{name}</span></h4>
        
        <label>
          <p>Nombre completo</p>
          <input name="info_request[fullname]" placeholder="Ingresa tu nombre completo" />
        </label>


        <label>
          <p>Correo electrónico</p>
          <input type="email" name="info_request[email]" placeholder="Ingresa tu correo electronico" required />
        </label>


        <label>
          <p>Teléfono de contacto</p>
          <input name="info_request[telephone]" placeholder="1 000 000 000" pattern="[0-9]{10}" required />
        </label>

        <label>
          <p>Mensaje personalizado</p>
          <textarea name="info_request[message]" placeholder="Copia tu mensaje" />
        </label>

        <p className="cta-request__subtitle">
          <span>{name}</span>
          {' '}
          se comunicará contigo en el menor tiempo posible.
        </p>

        <div className="cta-request__buttons">
          <button type="button" onClick={close} >Cancelar</button>        
          <button type="submit" >{sendIcon} Enviar</button>        
        </div>
      </form>
    </div>
  )
};

CtaFormModal.propTypes = {
  name: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
}

export default CtaFormModal;
