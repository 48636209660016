import React, { useState } from "react";

const close = (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
      fill="#262626"
    />
  </svg>
);

const download = (
  <svg
    width="10"
    height="12"
    viewBox="0 0 10 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.332031 11.6666H9.66537V10.3333H0.332031V11.6666ZM9.66537 4.33331H6.9987V0.333313H2.9987V4.33331H0.332031L4.9987 8.99998L9.66537 4.33331Z"
      fill="#A98F6F"
    />
  </svg>
);

const share = (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 9.74665C9.49333 9.74665 9.04 9.94665 8.69333 10.26L3.94 7.49332C3.97333 7.33999 4 7.18665 4 7.02665C4 6.86665 3.97333 6.71332 3.94 6.55999L8.64 3.81999C9 4.15332 9.47333 4.35999 10 4.35999C11.1067 4.35999 12 3.46665 12 2.35999C12 1.25332 11.1067 0.359985 10 0.359985C8.89333 0.359985 8 1.25332 8 2.35999C8 2.51999 8.02667 2.67332 8.06 2.82665L3.36 5.56665C3 5.23332 2.52667 5.02665 2 5.02665C0.893333 5.02665 0 5.91999 0 7.02665C0 8.13332 0.893333 9.02665 2 9.02665C2.52667 9.02665 3 8.81999 3.36 8.48665L8.10667 11.26C8.07333 11.4 8.05333 11.5467 8.05333 11.6933C8.05333 12.7667 8.92667 13.64 10 13.64C11.0733 13.64 11.9467 12.7667 11.9467 11.6933C11.9467 10.62 11.0733 9.74665 10 9.74665Z"
      fill="#A98F6F"
    />
  </svg>
);

const Drawer = ({ title, children, btnIcon }) => {
  const [showDrawer, setShowDrawer] = useState(false);

  return (
    <>
      {showDrawer && (
        <div className="cta-overlay">
          <div className="drawer">
            <h3>
              {title}
              <button onClick={() => setShowDrawer(false)}>{close}</button>
            </h3>

            <div className="drawer__content">
              {children}

              <div className="drawer__content__actions">
                <a href="#">
                  {download}
                  Descargar PDF
                </a>

                <a href="#">
                  {share}
                  Compartir
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
      <button onClick={() => setShowDrawer(true)} className="action-button">
        {btnIcon}
        <span>{title}</span>
      </button>
    </>
  );
};

export default Drawer;
