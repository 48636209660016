import React from 'react';
import PropTypes from 'prop-types';

const SearchResults = ({ results, icons }) => (
  <div className="search-results">
    {
      results.map(item => (
        <a
          href={item.url}
          key={item.url}
          className="search-results__suggestion"
          onClick={(e) => e.stopPropagation()}
        >
          {!item.icon && (<span
            className="search-results__icon"
            title={item.type}
            dangerouslySetInnerHTML={{__html: icons[item.type]}}
          />)}

          {item.icon && (
            <img
              className="search-results__icon"
              title={item.type}
              src={item.icon}
            />
          )}

          <span className="search-results__text">{item.name}</span>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.0039 9.414L7.39691 18.021L5.98291 16.607L14.5889 8H7.00391V6H18.0039V17H16.0039V9.414Z" fill="#021D49"/>
          </svg>
        </a>
      ))
    }
  </div>
);

SearchResults.propTypes = {
  results: PropTypes.arrayOf(PropTypes.object).isRequired,
  icon: PropTypes.objectOf(PropTypes.string).isRequired,
};

export default SearchResults;
